export enum AccessOrderBy {
  name = 'name',
  document = 'document',
  email = 'email',
}

export interface Access {
  id: string;
  document: number;
  name: string;
  last_name: string;
  email: string;
  price: number;
  ticket: string; //ticket type
  ticket_buy_on: Date;
  read: boolean;
  read_at: Date;
  read_by: string;
  email_sended: boolean;
}

export enum AccessesGender {
  male = 'M',
  female = 'F',
  other = 'O',
}
