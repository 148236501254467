import { ILIMITED_ACCESSES } from 'src/components/Settings/Settings';

const SUBSCRIPTION = 1;

export enum EventAccessesType {
  'free' = 50,
  'bronze' = 100,
  'silver' = 500,
  'gold' = ILIMITED_ACCESSES,
  'subscription' = SUBSCRIPTION,
}

export type TResizeProps = {
  maxWidth: number;
  maxHeight: number;
  compressFormat: string;
  quality: number;
  rotation: number;
};

export type TImageConversionProps = {
  normal: TResizeProps;
  small: TResizeProps;
};

export const ImageConversionProps: TImageConversionProps = {
  normal: {
    maxWidth: 1920,
    maxHeight: 1080,
    compressFormat: 'JPEG',
    quality: 5,
    rotation: 0,
  },
  small: {
    maxWidth: 20,
    maxHeight: 20,
    compressFormat: 'JPEG',
    quality: 100,
    rotation: 0,
  },
};
