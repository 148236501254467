import { create } from 'zustand';
import { createSelectors } from './createSelectors';
import createMessageSlice, { TMessage } from './messageSlice';
import createLoadingSlice, { TLoading } from './loadingSlice';

export type TStore = TMessage & TLoading;

const useStoreBase = create<TStore>((...args) => ({
  ...createMessageSlice(...args),
  ...createLoadingSlice(...args),
}));

const useStore = createSelectors(useStoreBase);
export default useStore;
