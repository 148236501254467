import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useAuth } from 'src/contexts/auth.context';
import { getDatabase, ref, onValue } from 'firebase/database';
import { EventStatusEnum, IEvent } from 'src/types/event.type';
import { getEvent, updateEvent } from 'src/services/database/event.service';
import { PaymentStatus } from 'src/types/payment.type';

export const MAX_DAYS_SINCE_FIRST_SCAN = 7;

const EventContext = createContext<IEventContext | null>(null);

interface IEventContext {
  getCurrentEvent: () => Promise<IEvent | undefined>;
  currentEvent?: IEvent;
  setCurrentEvent: (currentEvent?: IEvent) => void;
  isEditable: boolean;
}

export function useEventContext(): IEventContext | null {
  return useContext(EventContext);
}

export function EventProvider({ children }: any) {
  const auth = useAuth();
  const [currentEvent, setCurrentEvent] = useState<IEvent | undefined>();

  const isEditable = useMemo(() => {
    if (
      currentEvent?.payment &&
      currentEvent?.payment?.status !== PaymentStatus.approved &&
      currentEvent?.type === undefined
    )
      return false;
    const finalizeEvent = async () => {
      if (currentEvent?.id && auth?.currentCompany?.id)
        await updateEvent({ ...currentEvent, status: EventStatusEnum['finished'] }, auth.currentCompany.id);
    };
    if (currentEvent?.status !== EventStatusEnum['active']) return false;
    const currentDate = dayjs(new Date());
    const firstScan = dayjs(currentEvent?.firstScan);
    const finished = !!currentEvent?.firstScan && MAX_DAYS_SINCE_FIRST_SCAN <= firstScan.diff(currentDate, 'day');

    if (finished) finalizeEvent();

    return !currentEvent?.firstScan;
  }, [auth?.currentCompany?.id, currentEvent]);

  useEffect(() => {
    const db = getDatabase();
    const eventSnapshot = ref(db, `events/${auth?.currentCompany?.id}/${currentEvent?.id}`);
    const eventListenerRemove = onValue(eventSnapshot, getCurrentEvent);
    return () => {
      eventListenerRemove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentCompany, currentEvent?.id]);

  async function getCurrentEvent() {
    if (auth) {
      const { currentCompany } = auth;
      if (currentCompany && currentEvent) {
        const event = await getEvent(currentCompany.id, currentEvent?.id);
        setCurrentEvent(event);
        auth?.currentUser?.events &&
          event &&
          auth.setCurrentUser({
            ...auth.currentUser,
            events: auth.currentUser.events.map(item => {
              if (item.id === event.id) return event;
              return item;
            }),
          });
        return event;
      }
    }
  }

  const value = { getCurrentEvent, currentEvent, setCurrentEvent, isEditable };

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
}
