import { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import { UserItem } from 'src/types/user.type';

import { useAuth } from 'src/contexts/auth.context';
import { useLanguage } from 'src/contexts/language.context';
import { getCompanyUsers, removeUserCompany, updateUser } from 'src/services/database/user.service';
import { getDatabase, ref, onValue } from 'firebase/database';
import useStore from 'src/store';

const UsersContext = createContext<IUserContext | null>(null);

interface IUserContext {
  users: UserItem[];
  getUsers: Function;
  sendResetPassword: Function;
  removeUserFromCompany: Function;
  updateProfile: (firstName: string, lastName: string) => Promise<boolean>;
}

export function useUserContext(): IUserContext | null {
  return useContext(UsersContext);
}

export function UsersProvider({ children }: any) {
  const auth = useAuth();
  const showMessage = useStore.use.showMessage();
  const { language, getTranslation } = useLanguage();
  const [users, setUsers] = useState<UserItem[]>([]);

  useEffect(() => {
    const db = getDatabase();
    const adminSnapshot = ref(db, `companies/${auth?.currentCompany?.id}/admins`);
    const usersSnapshot = ref(db, `companies/${auth?.currentCompany?.id}/users`);
    const adminListenerRemove = onValue(adminSnapshot, getUsers);
    const userListenerRemove = onValue(usersSnapshot, getUsers);
    return () => {
      adminListenerRemove();
      userListenerRemove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentCompany]);

  async function getUsers() {
    if (auth) {
      const { currentCompany } = auth;
      if (currentCompany) {
        const users = await getCompanyUsers(currentCompany.id);
        setUsers(users);
        return users;
      }
    }
  }

  async function sendResetPassword(email: string) {
    try {
      const auth = getAuth();
      auth.languageCode = language;
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function removeUserFromCompany(company_uid: string, user_uid: string) {
    return await removeUserCompany(company_uid, user_uid);
  }

  async function updateProfile(firstName: string, lastName: string): Promise<boolean> {
    if (!auth?.currentUser?.uid) return false;
    if (!firstName.trim()) {
      showMessage('ATTRIBUTE_REQUIRED', 'error', { value: getTranslation('FIRST_NAME') });
      return false;
    }
    if (!lastName.trim()) {
      showMessage('ATTRIBUTE_REQUIRED', 'error', { value: getTranslation('LAST_NAME') });
      return false;
    }
    await updateUser(auth.currentUser.uid, undefined, undefined, firstName, lastName);
    return true;
  }

  const value = { users, getUsers, sendResetPassword, removeUserFromCompany, updateProfile };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}
