import { PaymentStatusResponse } from 'src/types/payment.type';

import { IApiResponse, IPaymentMethod } from './api/interfaces';
import { apiCallGetPaymentMethods, apiCallSendPayment } from './api/payment.api';

export const sendPaymentData = async (type: string, data: any): Promise<IApiResponse<any>> => {
  try {
    const response = await apiCallSendPayment(type, data);
    return {
      status: response.status as any as PaymentStatusResponse,
      data: response.data,
    };
  } catch (error: any) {
    return {
      status: PaymentStatusResponse.error,
      code: error?.code || '',
      message: error?.message || error,
    };
  }
};

export const getPaymentMethods = async (company_id: string): Promise<IApiResponse<IPaymentMethod[]>> => {
  try {
    const response = await apiCallGetPaymentMethods(company_id);
    const data: IPaymentMethod[] = response.data?.map(method => ({
      ...method,
      thumbnail: getPaymentMethodImage(method),
    }));

    return {
      status: response.status as any as PaymentStatusResponse,
      data: data,
    };
  } catch (error: any) {
    return {
      status: PaymentStatusResponse.error,
      code: error?.code || '',
      message: error?.message || error,
    };
  }
};

const getPaymentMethodImage = (method: IPaymentMethod): string => {
  let image = '';
  switch (method.name) {
    case 'American Express':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/fbf867c0-9108-11ed-87b1-fd4dd99fac51-m.svg';
      break;

    case 'Visa':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/a5f047d0-9be0-11ec-aad4-c3381f368aaf-m.svg';

      break;
    case 'Oca':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/d7e372a0-f39b-11eb-8e0d-6f4af49bf82e-m.svg';

      break;
    case 'Mastercard':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/aa2b8f70-5c85-11ec-ae75-df2bef173be2-m.svg';

      break;

    case 'Visa Débito':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/312238e0-571b-11e8-823a-758d95db88db-m.svg';

      break;
    case 'Redpagos':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/c2d9dbb0-f39b-11eb-9984-b7076edb0bb7-m.svg';

      break;
    case 'Mastercard Débito':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/aa2b8f70-5c85-11ec-ae75-df2bef173be2-m.svg';

      break;
    case 'Abitab':
      image = 'https://http2.mlstatic.com/storage/logos-api-admin/8c527760-f39a-11eb-826e-6db365b9e0dd-m.svg';

      break;

    default:
      image = method.thumbnail;
      break;
  }

  return image;
};
