import { useEffect, useState, FC } from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Delete from '@mui/icons-material/Delete';
import AlertTitle from '@mui/material/AlertTitle';
import ContentCopy from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Text from 'src/components/Text';

import { useAuth } from 'src/contexts/auth.context';
import { useLanguage } from 'src/contexts/language.context';
import { getCompanyApiKeys } from 'src/services/database/company.service';
import { IToken } from 'src/types/token.type';
import ModalCreateApiKey from './ModalCreateApiKey';
import { TCompanyApiKey } from 'src/types/companyApiKey.type';
import ModalDeleteApiKey from './ModalDeleteApiKey';
import useStore from 'src/store';

const emptyKey: TCompanyApiKey = {
  name: '',
  token: '',
};

const ApiKeys: FC = () => {
  const auth = useAuth();
  const showMessage = useStore.use.showMessage();
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [lastCreatedCompanyApiKey, setLastCreatedCompanyApiKey] = useState<TCompanyApiKey>(emptyKey);
  const [companyApiKeyToDelete, setCompanyApiKeyToDelete] = useState<TCompanyApiKey>(emptyKey);
  const [isInfoVisible, setIsInfoVisible] = useState<boolean>(true);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState<boolean>(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const { getTranslation } = useLanguage();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser, auth?.currentCompany]);

  const getData = async () => {
    if (!auth?.currentUser?.uid) return null;
    const tokens = await getCompanyApiKeys(auth?.currentCompany?.id || '');
    setTokens(tokens);
  };

  const onCloseCreateModal = (createdKey?: TCompanyApiKey): void => {
    if (createdKey) {
      setLastCreatedCompanyApiKey(createdKey);
    }
    getData();
    setIsModalCreateOpen(false);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showMessage('COMPANY_API_KEYS_TOKEN_COPY_SUCCESS', 'success');
      })
      .catch(() => {
        showMessage('COMPANY_API_KEYS_TOKEN_COPY_ERROR', 'error');
      });
  };

  const openDeleteConfirmModal = (companyApiKey: TCompanyApiKey): void => {
    setCompanyApiKeyToDelete(companyApiKey);
    setIsModalDeleteOpen(true);
  };
  const onCloseDeleteModal = (needRefresh: boolean): void => {
    if (needRefresh) getData();
    setIsModalDeleteOpen(false);
    setCompanyApiKeyToDelete(emptyKey);
  };

  const downloadJson = (companyApiKey: TCompanyApiKey) => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(companyApiKey, null, 2))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${companyApiKey.name}.json`;
    link.click();
  };

  return (
    <div className="border-t-2 mt-5">
      <div className="sm:flex sm:items-center ">
        <div className="sm:flex-auto">
          <Text bold>COMPANY_API_KEYS</Text>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none text-right">
          <button
            onClick={() => setIsModalCreateOpen(true)}
            className="bg-blue-600 px-3 py-1 rounded-md text-white font-bold transition-all duration-500"
          >
            <Text simple>CREATE</Text>
          </button>
        </div>
      </div>
      <Collapse in={isInfoVisible}>
        <Alert severity="info" className="mt-4" onClose={() => setIsInfoVisible(false)}>
          <Text>COMPANY_API_KEYS_CREATE_INFO</Text>
        </Alert>
      </Collapse>
      <Collapse in={lastCreatedCompanyApiKey.name !== ''}>
        <Alert className="mt-4" onClose={() => setLastCreatedCompanyApiKey(emptyKey)}>
          <AlertTitle>{getTranslation('COMPANY_API_KEYS_CREATED_SUCCESS')}</AlertTitle>
          <div className="bg-gray-50 shadow-inner rounded-lg p-3 mb-3 break-all">{lastCreatedCompanyApiKey.token}</div>
          <div className="flex flex-row">
            {typeof navigator.clipboard !== 'undefined' && (
              <button
                className="bg-green-600 px-3 py-1 rounded-md mr-3 flex flex-row"
                onClick={() => copyToClipboard(lastCreatedCompanyApiKey.token)}
              >
                <Text className="font-semibold" color="text-white">
                  COPY_TO_CLIPBOARD
                </Text>
                <ContentCopy className="text-white ml-2" />
              </button>
            )}
            <button
              className="bg-green-600 px-3 py-1 rounded-md mr-3 flex flex-row"
              onClick={() => downloadJson(lastCreatedCompanyApiKey)}
            >
              <Text className="font-semibold" color="text-white">
                DOWNLOAD
              </Text>
              <FileDownloadIcon className="text-white ml-2" />
            </button>
          </div>
        </Alert>
      </Collapse>
      <div className="mt-4 flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900">
                      <Text simple>NAME</Text>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-6"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tokens.length ? (
                    tokens.map(token => (
                      <tr key={token.id}>
                        <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900">
                          {token.name}
                        </td>
                        <td
                          className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium"
                          width={'10%'}
                        >
                          <button
                            className="text-red-600 hover:text-red-900"
                            onClick={() => openDeleteConfirmModal(token)}
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="relative whitespace-nowrap py-4 pl-3 pr-6 text-center text-sm font-medium italic"
                        align="center"
                      >
                        {getTranslation('NO_DATA_AVAILABLE')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalCreateApiKey isOpen={isModalCreateOpen} onClose={onCloseCreateModal} />
      <ModalDeleteApiKey
        isOpen={isModalDeleteOpen}
        onClose={onCloseDeleteModal}
        companyApiKey={companyApiKeyToDelete}
      />
    </div>
  );
};

export default ApiKeys;
