import { FC, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Text from 'src/components/Text';

import { useAuth } from 'src/contexts/auth.context';
import { useLanguage } from 'src/contexts/language.context';
import { createCompanyApiKey } from 'src/services/companyApyKeys.service';
import { CompanyApiKeyStatusResponse, TCompanyApiKey } from 'src/types/companyApiKey.type';
import useStore from 'src/store';

export interface IProps {
  isOpen: boolean;
  onClose: (lastCreatedKey?: TCompanyApiKey) => void;
}

const ModalCreateApiKey: FC<IProps> = ({ onClose, isOpen }) => {
  const auth = useAuth();
  const showMessage = useStore.use.showMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [companyId, setCompanyId] = useState<string>('');
  const { getTranslation } = useLanguage();

  useEffect(() => {
    if (auth?.currentCompany && !companyId) {
      setCompanyId(auth.currentCompany.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentCompany]);

  const resetForm = (): void => {
    setIsLoading(false);
    setName('');
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const handleOk = async () => {
    if (!name.trim()) {
      showMessage('ATTRIBUTE_REQUIRED', 'error', { value: getTranslation('NAME') });
      return;
    }

    setIsLoading(true);

    const response = await createCompanyApiKey(companyId, name);
    if (response.status === CompanyApiKeyStatusResponse.error) {
      showMessage(response.message || '', 'error');
      setIsLoading(false);
      return;
    }
    resetForm();
    onClose(response.data);
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      title={getTranslation('CREATE')}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    >
      <DialogTitle>
        <Text>CREATE</Text>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          <Text className="mb-1">NAME</Text>
          <input
            value={name}
            placeholder={getTranslation('NAME')}
            onChange={({ target: { value } }) => setName(value)}
            className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          <Text>CANCEL</Text>
        </Button>
        <LoadingButton onClick={handleOk} loading={isLoading}>
          <Text simple>ACCEPT</Text>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCreateApiKey;
