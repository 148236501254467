import React, { FC } from 'react';
import { CircularProgress } from '@mui/material';

interface IProps {
  isOpen: boolean;
}

const Loading: FC<IProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <main className="w-screen h-screen top-0 left-0 flex fixed !z-[100] bg-gray-800 bg-opacity-60 items-center">
      <CircularProgress size={35} className="m-auto animate-spin" color="primary" />
    </main>
  );
};

export default Loading;
