import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'src/App';
import 'src/styles/index.scss';
import reportWebVitals from 'src/reportWebVitals';
import initialization from './services/firebase.service';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement, BarElement);

// @ts-ignore
if (window.ga) window.ga('create', process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, 'auto');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
initialization();
