import { deleteObject, getStorage, listAll, ref, uploadBytesResumable } from 'firebase/storage';
import ImageResizer from 'react-image-file-resizer';
import { TResizeProps, ImageConversionProps } from 'src/constants/events';

const resizeFile = (file: File, { maxWidth, maxHeight, compressFormat, quality, rotation }: TResizeProps) =>
  new Promise(resolve => {
    ImageResizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      uri => {
        resolve(uri);
      },
      'blob',
    );
  });

const uploadToFirebaseStorage = async (uri: string, metadata: any, file: any) => {
  const storage = getStorage();
  const eventImageRef = ref(storage, uri);

  const uploadTask = uploadBytesResumable(eventImageRef, file, metadata);
  uploadTask.on(
    'state_changed',
    () => {},
    error => {
      throw new Error(error.message);
    },
  );
  return `https://firebasestorage.googleapis.com/v0/b/${eventImageRef.bucket}/o/${eventImageRef.fullPath.replace(
    /\//g,
    '%2F',
  )}?alt=media&token=8a31a43a-8dcc-4a08-ab0f-976ce86e41e7`;
};

interface IImagesURL {
  orig: string;
  small: string;
}

export const uploadImage = async (
  companyId: string,
  eventId: string,
  file: File,
  userId: string,
): Promise<IImagesURL> => {
  try {
    const metadata = {
      contentType: file?.type,
      customMetadata: { uploadedBy: userId },
    };
    const extension = file.type.match(/[^/]*$/);
    if (!extension) throw new Error('AN_ERROR_OCURRED');

    return {
      orig: await uploadToFirebaseStorage(`events/${companyId}/${eventId}/image.${extension[0]}`, metadata, file),
      small: await uploadToFirebaseStorage(
        `events/${companyId}/${eventId}/image_small.${extension[0]}`,
        metadata,
        await resizeFile(file, ImageConversionProps.small),
      ),
    };
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteImage = async (companyId: string, eventId: string): Promise<undefined> => {
  try {
    const storage = getStorage();
    const eventRef = ref(storage, `events/${companyId}/${eventId}`);

    const files = await listAll(eventRef);
    for (const item of files.items) {
      await deleteObject(item);
    }

    return;
  } catch (err: any) {
    throw new Error(err);
  }
};
