import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Main from 'src/components/Main';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'src/contexts/auth.context';
import { UsersProvider } from 'src/contexts/users.context';
import { StepsProvider } from 'src/contexts/signUp.context';
import { LanguageProvider } from './contexts/language.context';
import { CompaniesProvider } from './contexts/company.context';
import { EventProvider } from './contexts/event.context';

import 'react-toastify/dist/ReactToastify.css';
import 'src/index.css';
import { CssBaseline } from '@mui/material';
import { SecretsProvider } from './contexts/secret.context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <AuthProvider>
            <LanguageProvider>
              <StepsProvider>
                <UsersProvider>
                  <CompaniesProvider>
                    <SecretsProvider>
                      <EventProvider>
                        <CssBaseline />
                        <Main />
                        <ToastContainer />
                      </EventProvider>
                    </SecretsProvider>
                  </CompaniesProvider>
                </UsersProvider>
              </StepsProvider>
            </LanguageProvider>
          </AuthProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
