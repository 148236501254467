import { child, get, set, getDatabase, ref, update, remove } from 'firebase/database';

import { IEvent } from 'src/types/event.type';
import { ICompany } from 'src/types/company.type';
import { UserItem } from 'src/types/user.type';
import Languages from 'src/constants/languages';

export const getUserCompanies = async (user_uid: string): Promise<ICompany[]> => {
  const snapshot = await get(child(ref(getDatabase()), `users/${user_uid}/companies`));
  if (snapshot.exists()) return Object.values(snapshot.val());
  return [];
};

export const getCompanyUsers = async (company_uid: string): Promise<UserItem[]> => {
  const adminSnapshot = await get(child(ref(getDatabase()), `companies/${company_uid}/admins`));
  const usersSnapshot = await get(child(ref(getDatabase()), `companies/${company_uid}/users`));
  const users: UserItem[] = [];
  if (adminSnapshot.exists()) {
    const adminsObject = adminSnapshot.val();
    for (const key in adminsObject) {
      users.push({ id: key, email: adminsObject[key], isAdmin: true });
    }
  }
  if (usersSnapshot.exists()) {
    const usersObject = usersSnapshot.val();
    for (const key in usersObject) {
      users.push({ id: key, email: usersObject[key], isAdmin: false });
    }
  }
  return users;
};

export const getUser = async (user_uid: string): Promise<UserItem | null> => {
  const snapshot = await get(child(ref(getDatabase()), `users/${user_uid}`));
  if (!snapshot.exists()) return null;
  const user = snapshot.val();
  return {
    id: user_uid,
    email: user.data.email as string,
    firstName: user.data.firstName as string,
    lastName: user.data.lastName as string,
    language: user.data.language as Languages,
    companies: user.companies as ICompany[],
  };
};

export const removeUserCompany = async (company_uid: string, user_uid: string): Promise<boolean> => {
  try {
    await remove(child(ref(getDatabase()), `users/${user_uid}/companies/${company_uid}`));
    await remove(child(ref(getDatabase()), `companies/${company_uid}/admins/${user_uid}`));
    await remove(child(ref(getDatabase()), `companies/${company_uid}/users/${user_uid}`));
    return true;
  } catch (error) {
    return false;
  }
};

export const getCompanyEvents = async (company: string): Promise<IEvent[]> => {
  const snapshot = await get(child(ref(getDatabase()), `events/${company}`));
  if (snapshot.exists()) {
    let res: IEvent[] = [];
    snapshot.forEach(child => {
      const date = new Date(child.val().date);
      const allowToReadFrom = child.val().allowToReadFrom ? new Date(child.val().allowToReadFrom) : null;
      const allowToReadTo = child.val().allowToReadTo ? new Date(child.val().allowToReadTo) : null;
      res.push({ ...child.val(), date, allowToReadFrom, allowToReadTo });
    });
    return res;
  } else {
    return [];
  }
};

export const createUser = async (
  uid: string,
  companyKey: string,
  companyName: string,
  firstName: string,
  lastName: string,
  email: string,
  language: Languages,
): Promise<void> => {
  try {
    await set(child(ref(getDatabase()), `users/${uid}/data`), { firstName, lastName, email, language });
    await set(child(ref(getDatabase()), `users/${uid}/companies/${companyKey}`), {
      name: companyName,
      id: companyKey,
      default: true,
    });
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateUser = async (
  uid: string,
  company?: string,
  companyName?: string,
  firstName?: string,
  lastName?: string,
  language?: Languages,
  companyDefault?: boolean,
): Promise<void> => {
  try {
    const updates: any = {};
    if (firstName) updates[`users/${uid}/data/firstName`] = firstName;
    if (lastName) updates[`users/${uid}/data/lastName`] = lastName;
    if (language) updates[`users/${uid}/data/language`] = language;
    if (company && companyName)
      updates[`users/${uid}/companies/`] = {
        [company]: { name: companyName, id: company, ...(companyDefault && { default: companyDefault }) },
      };
    await update(child(ref(getDatabase()), '/'), updates);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const userExists = async (uid: string): Promise<boolean> => {
  try {
    const snapshot = await get(child(ref(getDatabase()), `users/${uid}`));
    return snapshot.exists();
  } catch (err: any) {
    return false;
  }
};

export const isAdmin = async (uid: string, company: string): Promise<boolean> => {
  try {
    const snapshot = await get(child(ref(getDatabase()), `companies/${company}/admins/${uid}`));
    return snapshot.exists();
  } catch (err: any) {
    return false;
  }
};

export const isOwner = async (uid: string, company: string): Promise<boolean> => {
  try {
    const snapshot = await get(child(ref(getDatabase()), `companies/${company}/createdBy`));
    if (snapshot.exists()) return snapshot.val() === uid;
    return false;
  } catch (err: any) {
    return false;
  }
};

export const addUserCompany = async (uid: string, companyId: string, companyName: string): Promise<boolean> => {
  try {
    await set(child(ref(getDatabase()), `users/${uid}/companies/${companyId}`), { name: companyName, id: companyId });
    return true;
  } catch (err: any) {
    return false;
  }
};

export const setCompanyDefault = async (uid: string, companyId: string, oldCompanyId?: string): Promise<boolean> => {
  try {
    if (oldCompanyId) await set(child(ref(getDatabase()), `users/${uid}/companies/${oldCompanyId}/default`), false);
    await set(child(ref(getDatabase()), `users/${uid}/companies/${companyId}/default`), true);
    return true;
  } catch (err: any) {
    return false;
  }
};
export const deleteUserHash = async (userUid: string): Promise<void> => {
  const snapshot = await get(child(ref(getDatabase()), `temporary_uid_hashes`));
  let hashToDelete: (string | null)[] = [];
  if (snapshot.exists())
    snapshot.forEach(userHash => {
      if (userHash.val().uid === userUid) {
        hashToDelete.push(userHash.key);
      }
    });
  for (const index in hashToDelete) {
    await remove(child(ref(getDatabase()), `temporary_uid_hashes/${hashToDelete[index]}`));
  }
};
