import { ValidationSchema } from 'src/contexts/signUp.context';

export const initialValues: ValidationSchema = {
  firstName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
    helperText: 'Custom error message',
  },
  lastName: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 2,
    maxLength: 20,
  },
  email: {
    value: '',
    error: '',
    required: true,
    validate: 'email',
  },
  password: {
    value: '',
    error: '',
    minLength: 6,
    required: true,
  },
  confirmPassword: {
    value: '',
    error: '',
    minLength: 6,
    required: true,
  },
  companyName: {
    value: '',
    required: true,
    validate: 'text',
    error: '',
  },
  country: {
    value: '',
    error: '',
    required: true,
    validate: 'text',
    minLength: 3,
    maxLength: 20,
  },
};
