const Spanish: { [key: string]: string } = {
  SIGN_IN: 'Iniciar sesión',
  FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
  NOT_HAVE_ACCOUNT: '¿No tienes cuenta? Registrate aquí',
  SIGN_IN_GOOGLE: 'Iniciar sesión con Google',
  WELCOME_MESSAGE: 'Bienvenido {{value}}',
  EMAIL: 'Correo electrónico',
  PASSWORD: 'Contraseña',

  CREATE_EVENT: 'Nuevo evento',
  VIEW_EVENTS: 'Ver eventos',
  NO_EVENTS_LAST_YEAR: 'Sin eventos registrados previos a la fecha actual',

  SIGN_UP: 'Registro',
  USER_SIGN_UP: 'Registro de usuario',
  COMPANY_SIGN_UP: 'Registro de empresa',
  COMPANY_SIGN_UP_MSG: 'No esta asociado a ninguna empresa. Para continuar cree su propia empresa',
  SIGN_UP_GOOGLE: 'Registrarse con Google',
  ALREADY_HAVE_ACCOUNT: '¿Ya tienes una cuenta? Iniciar sesión',
  FIRST_NAME: 'Nombre',
  YOUR_FIRST_NAME: 'Su nombre',
  LAST_NAME: 'Apellido',
  YOUR_LAST_NAME: 'Su apellido',
  ENTER_YOUR_NAME: 'Ingrese su nombre',
  ENTER_YOUR_EMAIL: 'Ingrese su email',
  YOUR_EMAIL: 'Su correo electrónico',
  YOUR_PASSWORD: 'Su contraseña',
  CONFIRM_PASSWORD: 'Confirmar contraseña',
  CONFIRM_YOUR_PASSWORD: 'Confirmar su contraseña',
  COMPANY_NAME: 'Nombre de la empresa',
  COUNTRY: 'País',
  COMPANY_COUNTRY: 'País de la empresa',
  ROLE: 'Rol',
  NEXT: 'Siguiente',
  EMAIL_ALREADY_IN_USE: 'Correo electrónico ya en uso',
  INVALID_EMAIL: 'Por favor, ingrese un correo electrónico válido',
  FIELD_IS_REQUIRED: 'Este campo es requerido',
  PASSWORD_CONFIRMATION_NOT_MATCH: 'La contraseña de confirmación no coincide',
  MINIMUM_CHARACTERS_IS_REQUIRED: 'Se requiere un mínimo de {{value}} caracteres',
  MAXIMUN_LENGTH_EXCEEDED: 'El largo máximo fue excedido',
  FIELD_ACCEPTS_TEXT_ONLY: 'Este campo acepta solamente texto',
  FIELD_ACCEPTS_NUMBERS_ONLY: 'Este campo acepta solamente números',
  ENTER_A_VALID_PHONE_NUMBER: 'Por favor, introduzca un número de teléfono válido',
  ENTER_A_VALID_ZIP_CODE: 'Por favor, introduzca un código zip válido',
  PROVIDE_A_VALID_VALUE: 'Por favor, introduzca un valor válido',
  SELECT_A_VALUE: 'Por favior, seleccione un valor',

  EDIT: 'Editar',
  CREATE: 'Nuevo',
  DELETE: 'Eliminar',
  DUPLICATE: 'Duplicar',
  ADD: 'Agregar',
  CANCEL: 'Cancelar',
  ACCEPT: 'Aceptar',
  ACTION_CANT_UNDONE: 'Esta acción no se puede deshacer',
  DO_YOU_WANT_DELETE_EVENT: '¿Quieres eliminar {{value}}?',
  EVENT_VIEW: 'Vista de evento',
  EVENTS: 'Eventos',
  EVENTS_CREATED: 'Eventos creados',
  EVENTS_FINALISED: 'Eventos finalizados',
  NEXT_EVENT: 'Siguiente evento',
  NO_EVENTS: 'Sin eventos',
  USERS: 'Usuarios',
  HOME: 'Inicio',

  RECOMENDED: 'Recomendado',
  EVENT_NOT_FOUND: 'No exste el evento',
  EVENT_DETAILS: 'Detalles del evento',
  NAME: 'Nombre',
  LOCATION: 'Ubicación',
  EVENT_DESCRIPTION: 'Descripción del evento',
  EVENT_IMAGE: 'Imagen del evento',
  DESKTOP_IMAGE: 'Imagen de escritorio',
  MOBILE_IMAGE: 'Imagen en móviles',
  EVENT_DATE: 'Fecha',
  EVENT_DATE_TIME: 'Fecha y hora del evento',
  EVENT_DATE_PAST: 'La fecha del evento debe ser posterior al momento de creación',
  EVENT_DATE_AFTER_TODAY: 'La fecha del evento debe ser posterior a la fecha de hoy',
  READ_FROM_DATE_BEFORE_EVENT_DATE: 'La fecha de lectura "desde" debe ser menor a la fecha del evento',
  READ_FROM_DATE_BEFORE_READ_TO: 'La fecha de lectura "desde" debe ser menor a la fecha de lectura "hasta"',
  EVENT_DATE_ERROR: 'Debería poder escanear QR antes de que comience el evento',
  EVENT_DATE_LATER: 'Esta fecha debe ser posterior al tiempo permitido para escanear',
  ALLOW_TO_READ_QR_FROM: 'Permitir ingresos desde',
  ALLOW_TO_READ_QR_UNTIL: 'Permitir ingresos hasta',
  SPECIAL_CONDITIONS: 'Condiciones especiales',
  SPECIAL_CONDITIONS_EXAMPLE: '(Ej: Mayor a 18 años, vestimenta formal, etc)',
  SOCIAL_LINKS: 'Redes sociales',
  IMAGE_LINK: 'Link de la imagen',
  EVENT_LANGUAGE: 'Idioma del evento',

  WHEN: 'Cuando',
  WHERE: 'Donde',
  WHERE_LOCATION_EVENT: '¿Donde voy?',
  QR_SCANNER: 'Escánear QR',
  SCAN_NOT_ALLOWED: 'Escaneo no permitido',
  EARLY_SCAN: 'Demasiado pronto para leer los accesos',
  LATE_SCAN: 'Demasiado tarde para leer accesos',
  ALREADY_SCANNED: 'Acceso ya escaneado',
  NOT_VALID: 'El código QR no es válido',
  REQUEST_PERMISSION: 'Solicitar permiso',
  SCAN_QR: 'Escanear',
  SCAN_QR_MESSAGE: 'Asegúrese de que su QR se encuentra sobre un fondo blanco',
  SUCCESS_SCAN: 'Escaneo exitoso',
  CAPTURE_NEW_ACCESS: 'Capturar nuevo acceso',
  FAILED_TO_SCAN: 'Error al escanear',

  EVENT_PUBLIC_URL: 'URL pública',
  COMPANY_PUBLIC_URL: 'URL pública',
  EVENT_PUBLIC_URL_COPY_SUCCESS: 'URL copiada al portapapeles',
  EVENT_PUBLIC_URL_COPY_ERROR: 'Ocurrió un error al tratar de copiar la URL',

  ACCESSES: 'Accesos',
  ACCESS_ADD: 'Agregar acceso',
  TOTAL_ACCESSES: 'Accesos totales',
  SCANNED_ACCESSES: 'Accesos escaneados',
  ACCESS_INFO:
    'Descubre como obtener estadísticas, generar los códigos QR y aprovechar al máximo esta funcionalidad leyendo las preguntas frecuentes',
  STATISTICS: 'Estadísticas',
  NUMBER_ACCESES: '# Accesos',
  TIME_ACCESES: 'Horario de los accesos escaneados',
  PRICE_ACCESES: 'Precio de los accesos escaneados',
  TICKET_BUY_ON: 'Horario compra',
  REVENUE: 'Ventas',
  FILTER: 'Filtro',
  DOCUMENT: 'Documento',
  READ: 'Leído',
  READ_DATE: 'Fecha de leída',
  READ_BY: 'Leído por',
  NO_RECORDS: 'Sin registros',
  ROWS_PER_PAGE: 'Registros por página',
  SELECTED: 'Seleccionados',
  ACCESS_REMOVE: 'Eliminar acceso/s',
  ACCESS_REMOVE_MESSAGE: '¿Seguro que desea eliminar {{value}} acceso/s?',
  ACCESSES_LOAD_DESCRIPTION:
    'Cargá los accesos en formato CSV, con hasta 5 columnas: document y name son obligatorias. <a href="/faqs" target="_blank" class="hover:text-blue-100 cursor-pointer ease-in-out">Aquí</a> puedes encontrar más tips útiles',
  IMAGE_LOAD_DESCRIPTION: 'Carga tu imagen. Los formatos aceptados son .png, .jpeg y .bmp',
  UPLOAD_FILE: 'Cargar archivo',
  GENDER_ERROR: 'El valor del campo gender debe ser: Femenino (o F), Masculino (o M) u Otro (u O)',
  PREVIEW: 'Vista previa',
  LANGUAGE: 'Lenguaje',
  COMPANY_LANGUAGE: 'Idioma de la empresa',
  PREDETERMINE: 'Hacer predeterminada',
  DEFAULT: 'Predeterminada',
  EMAIL_VERIFICATION_SENT: '¡Se le envió un correo electrónico de verificación!',
  SEND_EMAIL_PASSWORD: 'Recuperar contraseña',
  FORGOT_PASSWORD_MESSAGE:
    'Ingrese la dirección de correo electrónico asociada con su cuenta y le enviaremos un enlace para restablecer su contraseña',
  DUPLICATE_DOCUMENT_ON_ACCESS: 'Ya existe un acceso con el documento especificado',
  ALREADY_EXIST_UNIQUE_VALUE: 'Ya existe un acceso con el valor único especificado',
  MISSING_REQUIRED_FIELDS_ON_ACCESSES: 'Faltan datos requeridos en los accesos',
  COMPANY_IMAGE: 'Imagen de empresa',
  SHOW_ONLY_BANNER: 'Mostrar solamente el banner',
  SUCCESS_UPDATE: 'Actualización exitosa',
  FAILED_UPDATE: 'Actualización fallida',
  SEND_EMAIL: 'Enviar correo',
  SUCCESS_EMAIL_SEND: 'Envío de correo exitoso',
  FAILED_EMAIL_SEND: 'Envío de correo fallido',
  EMAIL_SENT: 'Correo enviado',

  INVITE: 'Invitar',
  REMOVE: 'Eliminar',
  USERS_REMOVE: 'Eliminar usuario',
  USERS_REMOVE_MESSAGE: '¿Seguro de que quiere eliminar al usuario?',

  INVALID_CREDENTIALS: 'Credenciales inválidas',
  YOU_MUST_FIRST_CREATE_ACCOUNT: 'Primero debe crear una cuenta',
  AN_ERROR_OCURRED: 'Ocurrió un error, inténtalo de nuevo más tarde',
  UNAUTHORIZED: 'No autorizado',

  USERS_CANNOT_REMOVE_ADMIN: 'No puedes eliminar un administrador',
  USERS_SUCCESS_REMOVE: 'Usuario eliminado correctamente',

  BACK: 'Atrás',
  LOGOUT: 'Cerrar sesión',
  SUBMIT: 'Enviar',
  SETTINGS: 'Ajustes',
  MY_PROFILE: 'Mi perfil',
  SAVE: 'Guardar',
  EXIT: 'Salir de la companía',

  ATTRIBUTE_REQUIRED: 'El atributo "{{value}}" es requerido',
  ATTRIBUTE_INVALID: '{{value}} no válido',
  TYPE_REQUIRED: 'Debes seleccionar un plan para el evento',

  JANUARY: 'Enero',
  FEBRUARY: 'Febrero',
  MARCH: 'Marzo',
  APRIL: 'Abril',
  MAY: 'Mayo',
  JUNE: 'Junio',
  JULY: 'Julio',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Septiembre',
  OCTOBER: 'Octubre',
  NOVEMBER: 'Noviembre',
  DECEMBER: 'Diciembre',

  ERROR_INVALID_ACTION: 'La solicitud ha expirado o el link ya fue utilizado',
  ERROR_GENERAL: 'Algo salió mal',
  TRY_AGAIN: 'Vuelve a intentarlo',
  PASSWORD_CHANGED: '¡Se actualizó la contraseña con éxito!',
  ERROR_PASSWORD: 'Las contraseñas no coinciden',
  ERROR_LENGTH: 'Mínimo {{value}} caracteres',
  RESET_PASSWORD_MSG: 'Resetea la contraseña para {{value}}',
  EMAIL_VERIFIED: '¡Se verificó el email con éxito!',

  SPANISH: 'Español',
  ENGLISH: 'Inglés',

  CONTACT_SENT: 'Tu mensaje ha sido enviado con exito!. Nos pondremos en contacto a la brevedad.',
  FIELDS_REQUIRED: 'Por favor complete todos los campos.',
  VERIFY_EMAIL: 'Por favor verifique el mail ingresado.',
  CARD_FIELDS_REQUIRED: 'Por favor complete los campos de la tarjeta',
  CARD_NUMBER: 'Número de la tarjeta',
  EXPIRATION_DATE: 'Fecha de expiración',
  SECURITY_CODE: 'Código de seguridad',
  CARD_HOLDER: 'Titular de la tarjeta',
  IDENTIFICATION_TYPE: 'Tipo de documentación',
  INSTALLMENTS: 'Cuotas',
  CARD_ISSUER: 'Emisor de la tarjeta',
  IDENTIFICATION_NUMBER: 'Número de documento',
  PAY: 'Pagar',

  MAX_NUMBER_COLS: 'El número máximo de columnas es {{value}}',

  FEMALE: 'Mujer',
  MALE: 'Hombre',
  OTHER: 'Otro',

  STATICS: 'Estadisticas',
  DETAILS: 'Detalles',

  ENTER: 'Ingresa',
  OR: 'o',
  REGISTER: 'Regístrate',
  NOW: 'Ahora',
  CONTACT: 'Contacto',
  PRODUCT: 'Producto',
  FEATURES: 'Características',
  HERO_HOME_SUBTITLE: 'Crea eventos y escanea de manera segura y sencilla',
  HERO_HOME_TITLE: 'Bienvenido a Event Manager!',
  HERO_HOME_CREATE_TITLE: 'Crear eventos y controlar asistencias nunca fue tan fácil!',
  HERO_HOME_CREATE_SUBTITLE: 'Te mostramos 4 simples pasos para que comiences a disfrutar',
  HERO_EVENTS_DETAILS: 'DETALLE DE EVENTOS',
  HERO_USER_DETAILS: 'DETALLE DEL USUARIO',
  HERO_CONTACT_US: 'Ante cualquier consulta no dudes en contactarnos!',
  FEATURES_TITLE: 'Características',
  FEATURES_SUBTITLE: 'Nuestro enfoque se basa en agilizar su trabajo sin perder de vista la seguridad',
  FEATURES_EASY_TITLE: 'Fácil de usar',
  FEATURES_EASY_SUBTITLE: 'Priorizamos la sencillez con el objetivo de fácilitar la utilización del sistema',
  FEATURES_MULTIDEVICE_TITLE: 'Multi dispositivo',
  FEATURES_MULTIDEVICE_SUBTITLE: 'Nuestro sistema es accesible desde cualquier dispositivo inteligente',
  FEATURES_ALCANCE_TITLE: 'Alcance',
  FEATURES_ALCANCE_SUBTITLE:
    'Compruebe el estado de sus eventos desde cualquier parte del mundo con solo tener acceso a internet',
  FEATURES_TECHNOLOGIES_TITLE: 'Tecnologías de punta',
  FEATURES_TECHNOLOGIES_SUBTITLE:
    'Nuestros sistemas fueron desarrollados con las tecnologías que son tendencias en el mercado de software actual',
  FEATURES_SUPPORT_TITLE: 'Soporte',
  FEATURES_SUPPORT_SUBTITLE:
    'Mantenemos una vía de comunicación directa con nuestro equipo de soporte, cualquier duda o inconveniente será atendido como se debe',
  FEATURES_SECURITY_TITLE: 'Seguridad',
  FEATURES_SECURITY_SUBTITLE:
    'Sus datos son nuestra prioridad, por lo que nos aseguramos de manejarlos de manera sensible y con la debida precaución',
  FLOW_REGISTER_CONTENT: 'Registrate ahora mismo, no tiene costo y se te solicitará únicamente información básica',
  FLOW_CREATE_TITLE: 'Crea tu empresa',
  FLOW_CREATE_CONTENT: 'Crea una empresa a la cual podrás asignarle eventos',
  FLOW_GENERATE_TITLE: 'Genera eventos',
  FLOW_GENERATE_CONTENT: 'Genera eventos y crea los accesos de los invitados',
  FLOW_SCAN_TITLE: 'Escanea',
  FLOW_SCAN_CONTENT: 'Escanea los códigos y registra los momentos precisos en lo que tus invitados llegaron',

  COMPANY: 'Compañía',
  ABOUT_US: 'Sobre nosotros',
  ADDRESS: 'Dirección',
  PHONE: 'Teléfono',
  CONTACT_US: 'Contáctenos',
  CONTACT_US_MSG: 'Déjenos sus comentarios y nos pondremos en contacto con usted a la brevedad',
  FULL_NAME: 'Nombre completo',
  MESSAGE: 'Mensaje',
  MESSAGE_DESCRIPTION: 'Escriba su consulta/sugerencía aquí',
  WELL_GET_BACK: 'Le responderemos a la brevedad',
  SEND_MESSAGE: 'Enviar mensaje',

  METHOD_NOT_IMPLEMENTED: 'Método no implementado',

  EXIT_COMPANY_SUCCESS: 'Has salido de la companía exitosamente',
  EXIT_COMPANY_ERROR: 'Ha ocurrido un error al salir de la companía',

  LENGHT_REACHED: 'Ya tienes 5 columnas, por favor asegurate que los accesos tengan dicho formato.',
  DOCUMENT_FIELD_REQUIRED: 'Debes tener relleno el campo documento en tus accesos.',
  NAME_FIELD_REQUIRED: 'Debes tener relleno el campo nombre en tus accesos.',
  ACCESS_ALERT_TITLE: 'Agregando columnas nuevas?',
  ACCESS_ALERT_TEXT: ' Estas agregando nuevas columnas y generarán valores vacíos.',
  DISAGREE: 'Cancelar',
  AGREE: 'Aceptar',
  EMPTY_FILE: 'Has subido un archivo vacío',
  SEND_MAILS_TO_ACCESSES: 'Quieres enviar un correo a cada nuevo acceso con su respectivo codigo QR?',
  SEND_MAIL: 'Enviar correos',
  SEND_MAIL_TO_SINGLE_ACCESS: 'Quieres enviar un correo al nuevo acceso con su respectivo codigo QR?',

  NO_ACCESS_DATA: 'Sin datos de accesos',
  NO_REVENUE_DATA: 'Sin datos de ventas',
  NO_GENDER_DATA: 'Sin datos de géneros',
  ACCESSES_PRICES: 'Precios de los accesos',
  NO_PRICES_DATA: 'Sin datos de precios',

  SUBSCRIPTION: 'Subscripción',
  ACTUAL_SUBSCRIPTION: 'Subscripción actual',
  PRICE: 'Precio',
  FREE: 'Gratuito',
  CHANGE: 'Cambiar',
  ANNUAL_DISCOUNT: '(10% de descuento en pago anual)',
  SUBSCRIPTION_SUCCESS_UPDATED: 'Subscripción actualizada correctamente',
  SUBSCRIPTION_SUCCESS_CANCELED: 'Subscripción cancelada correctamente',
  CANCEL_SUBSCRIPTION: 'Confirmar eliminación de suscripción',
  CANCEL_SUBSCRIPTION_MSG: '¿Está seguro de que desea cancelar su suscripción?',
  CHANGE_SUBSCRIPTION: 'Cambiar suscripción',
  CHANGE_SUBSCRIPTION_MSG: '¿Está seguro de que desea cambiar su suscripción?',

  PROCESSING_REQUEST: 'Procesando su solicitud',
  WAIT_MOMENT: 'Espere un momento por favor',

  INCLUDED_IN_SUBSCRIPTION: '(Incluído en tu subscripción)',
  ILIMITED_ACCESSES: 'Accesos ilimitados',
  TAGS: 'Etiquetas del evento',

  EVENT_NOT_EDITABLE: 'Se han leído accesos de este evento y ya no es posible editarlo',
  EVENT_NOT_SCANNABLE: 'Este evento ha sido eliminado, ya no pueden escanearse accesos',

  TOO_MANY_ACCESSES: 'La lista de accesos es mayor a la permitida, le quedan {{value}} accesos por ingresar.',
  ACCESSES_SUCCESFULLY_ADDED: 'Los accesos se agregaron exitosamente',
  EMPTY_FIELDS: 'Hay campos vacíos',
  FIELD_IS_EMPTY: 'El campo {{value}} es requerido',

  SCANN_TIME: 'Una vez leído el primer acceso se asignará una fecha limite para que el evento sea modificado',
  SCANN_TIME_LIMIT: 'Has leido el primer acceso el día {{value}}. Tienes 7 días para modificar el evento.',
  DANGER: 'Ten cuidado',

  FILTER_STATUS: 'Filtrar por estado',
  FILTER_STATUS_MOBILE: 'Estado',
  FILTER_TAGS: 'Filtrar por etiquetas',
  FILTER_TAGS_MOBILE: 'Etiquetas',

  Q1: '¿Cómo hago para crearme una cuenta? ¿Es necesario contar con una compañía?',
  Q2: '¿Cuál es el formato para cargar los accesos? ¿Qué consideraciones debo tener en cuenta?',
  Q3: '¿Cómo obtengo los códigos QR para mis accesos?',
  Q4: '¿Debo descargar una aplicación para escanear los códigos de acceso?',
  Q5: '¿Puedo invitar a mi compañía a un usuario que no posee cuenta en la aplicación?',
  Q6: '¿Cuáles son los tipos de planes disponibles y cuáles son sus costos?',
  Q7: 'Si contrato una suscripción, ¿pierdo los 3 eventos gratuitos?',
  Q8: '¿Cuáles son los métodos de pago habilitados?',

  A1: 'Puedes crearla desde el boton "Regístrate" ubicado al inicio. Allí debes elegir si quieres crear la cuenta automaticamente con google o prefieres completar los datos requeridos. No es necesario tener una empresa registrada a tu nombre, con companía denominamos un grupo o comunidad del cual tu podras al ser dueño tener control sobre la misma y nada impide la existencia de otra companía con el mismo nombre que el suyo.',
  A2: 'Los accesos deben ser cargados en formato CSV, con hasta 5 columnas de las cuales "document" y "name" son obligatorias, y 3 más opcionales. Si usas las columnas del template, podrás ver gráficos de estos campos. La columna "gender" debe completarse con el valor F para femenino, M para masculino, y O en otro caso.',
  A3: '<p>Puedes o bien generarlos en la aplicacion o haberlos generado de manera externa a la misma.</p><p>Una vez hayas cargado los accesos del evento será posible volver a descargar la lista a través del botón "descargar accesos".</p><p>En este archivo habrá una columna con el link al código QR de cada uno de los accesos.</p>',
  A4: 'En la pestaña de la descripcion del evento contarás con un boton el cual te permite escanear los QR desde un dispositivo celular sin necesidad de descargar una aplicación aparte',
  A5: 'Puedes invitarlo a su mail personal, pero para el poder hacer uso de la misma debe loguearse con el mismo mail al cual se le envió la notificación.',
  A6: 'Los mismos se encuentran detallados en la sección "Pricing" con sus respectivos beneficios.',
  A7: 'No, sigues contando con 3 eventos de 50 accesos gratis que puedes utilizar cuando desees.',
  A8: 'Puedes pagar utilizando Mercado Pago.',

  HAVE_QUESTION: 'Tienes preguntas?',
  FAQ: 'Preguntas frecuentes',
  STILL_HAVE_QUESTION: 'Aún tienes preguntas?',

  SUGGESTIONS: 'Sugerencias',
  TIPS: 'Puedes encontrar sugerencias en cuanto al funcionamiento de la aplicación ',
  HERE: 'aquí',

  T_USERS_SUGGESTION_1: '<p>Manejo de usuarios y roles</p>',
  T_USERS_SUGGESTION_2: '<p>Invitaciones a usuarios</p>',
  T_USERS_SUGGESTION_3: '<p>Subscripciones o planes particulares para eventos</p>',
  A_USERS_SUGGESTION_1:
    '<p>Tener en cuenta que puede acceder a diferentes funcionalidades, si usted es dueño de la compañía, si es invitado o si es administrador, le detallaremos esa información a continuación.</p>',
  A_USERS_SUGGESTION_2:
    '<p>Si el usuario no tiene cuenta en la aplicación, recomendamos que chequee su casilla de correo electrónico y siga los pasos que se encuentran detallados en el mail para acceder. Ante cualquier inconveniente puede contactarnos rellenando <a href="#contact">este</a> formulario</p>',
  A_USERS_SUGGESTION_3:
    '<p>Recomendamos la subscripción si se trata de un usuario que utilizará la aplicación regularmente, en caso contrario puedes contratar un plan destinado a corto plazo.</p> ',
  T_EVENTS_SUGGESTION_1: '<p>Fecha límite de modificación del evento</p>',
  T_EVENTS_SUGGESTION_2: '<p>Cómo obtener estadísticas del evento</p>',
  A_EVENTS_SUGGESTION_1:
    '<p>Luego de la primer lectura, el evento tendrá un plazo de <span class="text-blue-700">7 días</span> para que se bloquee cualquiera de sus modificaciones, pasando a ser así, un evento finalizado</p>',
  A_EVENTS_SUGGESTION_2:
    '<p>Ofrecemos estadísticas acerca de la cantidad de accesos al evento agrupados por género así como también las ventas totales solo si proporcionas al momento de cargar los accesos la columna "gender" y la columna "price", en caso contrario igualmente proporcionamos el total de accesos, el total de accesos leídos, y una gráfica mostrando la cantidad de accesos en los diferentes horarios. <br> Creemos que todos estos datos te ayudaran con próximos eventos.</p>',
  T_ACCESS_SUGGESTION_1: '<p>Seguridad en códigos QR</p>',
  T_ACCESS_SUGGESTION_2: '<p>Formato de carga del csv</p>',
  T_ACCESS_SUGGESTION_3: '<p>Genera tu código QR con nosotros</p>',
  T_ACCESS_SUGGESTION_4: '<p>Lee tu acceso generado externamente</p>',
  A_ACCESS_SUGGESTION_1:
    '<p>Recomendamos que genere los códigos con la mayor seguridad posible para evitar la falsificación de identidades, por ejemplo agregando una columna con una clave autogenerada.</p>',
  A_ACCESS_SUGGESTION_2:
    '<p>El csv que contiene los accesos del evento debe contener las columnas documento y nombre obligatoriamente, adicionalmente debe tener en cuenta que no puede generar accesos que contengan más de 5 columnas es decir dispone de 3 campos los cuales son opcionales y personalizables. <br>Si carga un acceso con campos personalizados, todos los accesos posteriores cargados mediante otro csv tendrán esos campos con su respectivo valor o con valores vacíos en caso de no haberlos completado.</p>',
  A_ACCESS_SUGGESTION_3: '<p>Puedes generar tu código QR con nosotros solo debes solicitarlo <a href="#">aqui</a>.</p>',
  A_ACCESS_SUGGESTION_4:
    '<p>A modo de ejemplo si contamos con un acceso asociado al documento <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> 12345678 </span> y al nombre <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> ejemplo </span> deberiamos generar el qr con el siguiente formato: <span class="bg-gray-300 mt-1 rounded-sm p-1 text-xs shadow font-medium text-black">{"document":"12345678","name":"ejemplo"}</span>.</p><br> <p>Si el acceso cuenta con campos custom por ejemplo <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> gender </span> o <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> price </span> se deben agregar los mismos de forma similar. </p> <br><p class="text-xs text-black font-thin"> Notar que como gender solo aceptamos female, male u other.</p>',

  active: 'Activo',
  finished: 'Finalizado',
  deleted: 'Eliminado',
  PUBLIC: 'Público',
  PRIVATE: 'Privado',

  DOWNLOAD_CSV: 'Descargar accesos',

  HELPER_LOCATION: 'Para mayor precisión escriba Calle número, Ciudad.',
  GEOLOCATION_DENIED_AND_NO_LOCATION:
    'Debes aceptar utilizar tu ubicación actual o escribir una direccion en el campo correspondiente.',

  PAYMENT_PENDING: 'Pago pendiente',
  PAYMENT_PENDING_MESSAGE: 'Su pago se encuentra en proceso, le notificaremos cuando tengamos novedades sobre el mismo',
  CLOSE: 'Cerrar',

  CC_REJECTED_BAD_FILLED_CARD_NUMBER: 'Revisa el número de tarjeta.',
  CC_REJECTED_BAD_FILLED_DATE: 'Revisa la fecha de vencimiento.',
  CC_REJECTED_BAD_FILLED_OTHER: 'Revisa los datos.',
  CC_REJECTED_BAD_FILLED_SECURITY_CODE: 'Revisa el código de seguridad de la tarjeta.',
  CC_REJECTED_BLACKLIST: 'No pudimos procesar tu pago.',
  CC_REJECTED_CALL_FOR_AUTHORIZE: 'Debes autorizar ante el medio de pago el pago de US$ {{amount}}.',
  CC_REJECTED_CARD_DISABLED:
    'Llama a su medio de pago para activar tu tarjeta o usa otro medio de pago. El teléfono está al dorso de tu tarjeta.',
  CC_REJECTED_CARD_ERROR: 'No pudimos procesar tu pago.',
  CC_REJECTED_DUPLICATED_PAYMENT:
    'Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
  CC_REJECTED_HIGH_RISK:
    'Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.',
  CC_REJECTED_INSUFFICIENT_AMOUNT: 'Tu medio de pago no tiene fondos suficientes.',
  CC_REJECTED_INVALID_INSTALLMENTS: 'Medio de pago no procesa pagos en cuotas.',
  CC_REJECTED_MAX_ATTEMPTS: 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago',
  CC_REJECTED_OTHER_REASON: 'Medio de pago no procesó el pago.',

  EVENT_PAYMENT_REJECTED: 'Su pago ha sido rechazado.',
  EVENT_PAYMENT_CANCELLED: 'Haz cancelado el pago.',
  EVENT_PAYMENT_PENDING: 'Estamos procesando su pago, le notificaremos cuando obtengamos respuesta.',
  EVENT_PAYMENT_APPROVED: 'Evento adquirido el {{date}}',

  WRONG_CSV_FORMAT: 'Por favor verifique el formato del csv dado que no es el debido. ',

  RETRY_PAYMENT: 'Reintentar pago',

  EXTERNAL_QR_1: '¿Cómo debo generar el qr para que se lea correctamente? Contestado ',
  EXTERNAL_QR_2: ' en la sección accesos.',

  COPY_TO_CLIPBOARD: 'Copiar al portapapeles',
  NO_DATA_AVAILABLE: 'No hay datos disponibles',
  DOWNLOAD: 'Descargar',

  COMPANY_API_KEYS: 'Api keys',
  COMPANY_API_KEYS_CREATED_SUCCESS: 'El api key fue creada exitosamente',
  COMPANY_API_KEYS_DELETED_SUCCESS: 'El api key fue eliminada exitosamente',
  COMPANY_API_KEYS_DELETED_CONFIRM_VALUE: 'Por favor escriba <b>{{value}}</b> para confirmar',
  COMPANY_API_KEYS_DELETED_CONFIRM_INFO:
    'Esta acción <b>no se podrá revertir</b>. Esto eliminará el api key <b>{{value}}</b> de forma permanente, si tuviera que usarlo en el futuro tendrá que crear uno nuevo',
  COMPANY_API_KEYS_TOKEN_COPY_SUCCESS: 'El token fue copiado al portapapeles',
  COMPANY_API_KEYS_TOKEN_COPY_ERROR: 'Ocurrió un error al tratar de copiar el token',
  COMPANY_API_KEYS_CREATE_INFO:
    'Al crear un api key para un compañía, guarde el token en un lugar seguro. El token solo es accesible al momento de crearlo. Si usted pierde el token tendrá que eliminar el acceso y crear uno nuevo',
  MERCADO_PAGO_KEYS: 'Claves de Mercado pago',
  SECRET: 'Secreto',

  COMPANY_API_KEYS_REMOVE_DIALOG_TITLE: '¿Está seguro de que desea eliminar el api key de la compañía?',

  VALID: 'Vigencia desde',
  EXPIRY: 'Valida hasta',

  NOT_FOUND: 'Página no encontrada',
  NOT_FOUND_DESCRIPTION: '¡Lo sentimos! Visite nuestra página de inicio para llegar a donde necesita ir.',
  GO_HOME: 'Ir a Inicio',

  CANT_DELETE_READ_ACCESS: 'No puedes borrar accesos escaneados',

  INVITE_NEW_USER: 'Invitar nuevo usuario',

  EVENT_TICKETS: 'Entradas del evento',
  ADD_TICKETS: 'Agregar entrada',
  TICKET_AVAILABLE_UNTIL: 'Venta valida hasta',
  TICKET_PRICE: 'Precio de la entrada',
  TICKET_TYPE: 'Tipo de entrada',
  TICKET_QUANTITY: 'Cantidad habilitada',
  PENDING_TICKET_QUANTITY: 'Cantidad pendiente',
  INVALID_DATE: 'Debe ser posterior a la fecha actual.',
  TICKETS_UPDATED: 'Has actualizado exitosamente las entradas al evento',
  TICKET_ID: 'Id de la entrada:',
  NEGATIVE_FIELDS: 'Hay campos con valores negativos.',
  CONTACT_TO_ADD_AND_BUY_TICKETS: 'Para habilitar la creación y venta de entradas',

  PASSWORD_REQUIRED: 'La contraseña es requerida',
  CONFIRMATION_PASSWORD_REQUIRED: 'Debes confirmar la contraseña',
  PASSWORD_LENGHT_REQUIRED: 'La contraseña debe tener al menos 6 caracteres',
  CHANGE_PASSWORD_NEXT_STEP: 'Luego de completado el formulario debes loguearte con las credenciales correspondientes',
  ENTER_PASSWORD: ' Ingresar contraseña',
  RIGHTS_RESERVED: '2023 Qr Event Manager. All rights reserved.',

  PAYMENT_METHODS: 'Métodos de pago',

  OPTIONAL_VALIDATIONS: 'Validaciones opcionales',
  VALIDATE_DNI: 'Validate DNI to access the event',
  PLACE: 'Lugar',
  PINPOINT_ON_MAP: 'Señalar en el mapa',
  HIDE_MAP_PINPOINT: 'Ocultar señalamientos del mapa',
  BUY_TICKET: 'Comprar entrada',
  TICKETS_UNAVAILABLE: 'La cantidad de entradas supera el máximo disponible.',
  TICKETS_GREATER_THAT_ZERO: 'La cantidad de entradas debe ser mayor a 0.',
  TOTAL: 'Total',
  CUSTOMER_INFO: 'Informacion del comprador:',
  COMPANION_INFO: 'Informacion del acompañante',
  BUY_TICKETS: 'Compra de entradas para',
  SELECT_TICKETS_AMOUNT: 'Seleccione la cantidad de entradas',
  ENTER_TICKETS_INFO: 'Ingrese los datos solicitados',
  GO: 'voy',
  NO_TICKETS_AVAILABLE: 'No quedan tickets',
  TICKETS_LEFT: 'Quedan pocos disponibles',
  RETURN_HOME: 'Volver',
  FEW_TICKETS_LEFT: 'Quedan pocas entradas',
  TICKETS_AVAILABLE: 'Entradas disponibles',
  VERY_FEW_TICKETS_LEFT: 'Quedan muy pocas entradas',
  X_TICKETS_LEFT: 'Quedan únicamente {{value}} entradas',
  NO_AVAILABLE_TICKETS: 'No quedan tickets disponibles',
  DESCRIPTION: 'Descripción',
  QUANTITY_MUST_BE_GREATER_THAN_BUYED: 'La cantidad de tickets debe ser mayor a la cantidad de tickets comprados',
  GREATER_THAN_X: 'Mayor a {{x}}',
  BETWEEN_X_AND_Y: 'Entre {{x}} y {{y}}',
  LOCATE: 'Localizar',

  TICKET_OWNER_INFORMATION: 'Información de dueños de los tickets "{{value}}"',
  SUMMARY: 'Resumen',
  THANKS_FOR_PURCHASE: '¡Gracias por su compra!',
  BUY_ANOTHER_TICKET: 'Comprar otra entrada',
  INVALID_STEP: 'Paso inválido',
  SELECT_PAYMENT_GATEWAY: 'Seleccione una pasarela de pago',

  EVENT_CUSTOM_FIELDS: 'Campos custom del evento',
  ADD_FIELD: 'Agregar campo',
  REQUIRED: 'Requiredo',
  UNIQUE: 'Único',
  TYPE: 'Tipo',
  TEXT: 'Texto',
  NUMBER: 'Número',
  DATE: 'Fecha',
  DONT_SHOW_ON_QR_READ: 'No mostrar al leer QR',
  VALIDATIONS: 'Validaciones',

  //ERROR CODES
  UNEXPECTED_ERROR: 'Error inesperado. Intente nuevamente',
  UNKNOWN:
    'Ocurrió un error mientras procesabamos tu pago. Intente nuevamente, y, si el error persiste, por favor contáctanos',
  BODY_VALIDATION_ERROR:
    'Ocurrió un error mientras procesabamos tu pago. Intente nuevamente, y, si el error persiste, por favor contáctanos',
  GATEWAY_PAYMENT_ERROR:
    'Ocurrió un error mientras procesabamos tu pago. Intente nuevamente, y, si el error persiste, por favor contáctanos',
  CARD_VALIDATION_ERROR: 'Los datos de su tarjeta no son correctos. Por favor, verifíquelos e intente nuevamente.',
};

export default Spanish;
