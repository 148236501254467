import { StateCreator } from 'zustand';
import { TypeOptions } from 'react-toastify';
import { TStore } from './index';

export type TMessage = {
  message: string;
  type: TypeOptions;
  options?: any;
  showMessage: (message: string, type: TypeOptions, options?: any) => void;
  clearMessage: () => void;
};

const createMessageSlice: StateCreator<TStore, [], [], TMessage> = set => ({
  message: '',
  type: 'success',
  options: null,
  showMessage: (message: string, type: TypeOptions, options?: any) =>
    set((state: any) => ({
      message,
      type,
      options,
    })),
  clearMessage: () =>
    set((state: any) => ({
      message: '',
      type: 'success',
      options: null,
    })),
});

export default createMessageSlice;
