import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  User as FirebaseUser,
  deleteUser,
  sendEmailVerification,
} from 'firebase/auth';

import { User } from 'src/types/user.type';
import { createEvent } from 'src/services/database/event.service';
import { createCompany, getCompany } from 'src/services/database/company.service';
import {
  getCompanyEvents,
  getUserCompanies,
  createUser,
  userExists,
  isAdmin as authorization,
  isOwner as owner,
  setCompanyDefault,
  getUser,
  deleteUserHash,
} from 'src/services/database/user.service';
import { ICompany } from 'src/types/company.type';
import { useLanguage } from './language.context';
import useLocalStorage from 'src/utils/hooks/useLocalStorage';
import useStore from 'src/store';

const googleProvider = new GoogleAuthProvider();
const AuthContext = createContext<IAuth | null>(null);

export interface IAuth {
  currentCompany?: ICompany | null;
  currentUser?: User | null;
  logIn?: (email: string, password: string) => Promise<UserCredential | void>;
  logOut?: () => Promise<void>;
  signUp?: (
    email: string,
    password: string,
    company: string,
    country: string,
    firstName: string,
    lastName: string,
  ) => Promise<FirebaseUser | void>;
  signUpWithGoogle?: () => Promise<UserCredential>;
  signInWithGoogle?: () => Promise<UserCredential>;
  initializeAdmin?: (
    uid: string,
    company: string,
    country: string,
    email: string,
    firstName: string,
    lastName: string,
  ) => Promise<void>;
  setCurrentUser: (currentUser: User | null) => void;
  setCurrentCompany: (currentCompany: ICompany | null) => void;
  switchCompany: (newCompany: ICompany) => void;
  selectDefault: (uid: string, companyId: string, oldCompanyId?: string) => Promise<void>;
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuth: boolean) => void;
}

export function useAuth(): IAuth | null {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage<boolean>('@IsAuthenticated', false);
  const [currentCompany, setCurrentCompany] = useState<ICompany | null>();
  const [googleUser, setGoogleUser] = useState<UserCredential | null>();
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const showMessage = useStore.use.showMessage();
  const showLoading = useStore.use.showLoading();
  const dismissLoading = useStore.use.dismissLoading();

  const logOutUnverifiedUser = async (user: FirebaseUser) => {
    const auth = getAuth();
    auth.languageCode = language;
    logOut();
    try {
      await sendEmailVerification(user);
      showMessage('EMAIL_VERIFICATION_SENT', 'info');
      navigate('/login');
    } catch (e: any) {
      showMessage(e.message, 'error');
    }
  };

  async function logIn(email: string, password: string) {
    const user = await signInWithEmailAndPassword(getAuth(), email, password);
    if (!user) throw new Error('AN_ERROR_OCURRED');
    if (await userExists(user.user.uid)) {
      if (!user.user.emailVerified) return logOutUnverifiedUser(user.user);
      return user;
    }
    logOut();
    deleteUser(user.user);
    throw new Error('auth/first-create-account');
  }

  async function signUpWithGoogle() {
    return await signInWithPopup(getAuth(), googleProvider);
  }

  async function logOut() {
    return signOut(getAuth());
  }

  async function signUp(
    email: string,
    password: string,
    company: string,
    country: string,
    firstName: string,
    lastName: string,
  ) {
    const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
    await initializeAdmin(user.uid, company, country, email, firstName, lastName);
    if (!user.emailVerified) {
      logOutUnverifiedUser(user);
    }
    return user;
  }

  async function signInWithGoogle() {
    const user = await signInWithPopup(getAuth(), googleProvider);
    await deleteUserHash(user.user.uid);
    if (!user) throw new Error('AN_ERROR_OCURRED');
    if (await userExists(user.user.uid)) {
      setGoogleUser(user);
      return user;
    }
    logOut();
    deleteUser(user.user);
    throw new Error('auth/first-create-account');
  }

  async function initializeAdmin(
    uid: string,
    companyName: string,
    country: string,
    email: string,
    firstName: string,
    lastName: string,
  ) {
    const newCompany = await createCompany(companyName, country, { [uid]: email });
    await createUser(uid, newCompany, companyName, firstName, lastName, email, language);
    const newEvent = await createEvent(newCompany, uid);

    if (googleUser?.user) {
      const { user } = googleUser;
      if (newEvent) setCurrentValues(user);
    } else {
      const user = getAuth().currentUser;
      if (user && user.emailVerified) setCurrentValues(user);
    }
    return;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      if (user) {
        if (user.emailVerified && (await userExists(user.uid))) setCurrentValues(user);
      } else {
        setCurrentUser(user);
      }
      setLoading(false);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setCurrentValues(user: FirebaseUser | User, company?: ICompany): Promise<void> {
    location.pathname !== '/' && showLoading();
    const companies = await getUserCompanies(user.uid);
    const userProfile = await getUser(user.uid);
    let companyDefault = company
      ? companies.find(item => company.id === item.id)
      : companies.find(item => item.default) || companies[0];
    if (!companyDefault) {
      setCurrentUser({ ...user, isAdmin: true, isOwner: false, language: userProfile?.language });
      dismissLoading();
      return navigate('/company/create');
    }
    const events = await getCompanyEvents(companyDefault?.id);
    const isAdmin: boolean = await authorization(user.uid, companyDefault?.id);
    const isOwner: boolean = await owner(user.uid, companyDefault?.id);
    companyDefault = (await getCompany(companyDefault.id)) ?? undefined;
    setCurrentCompany(companyDefault);
    setCurrentUser({ ...user, companies: companies, events, isAdmin, isOwner, language: userProfile?.language });
    dismissLoading();
  }

  async function switchCompany(newCompany: ICompany) {
    if (!currentUser) return;
    setCurrentValues(currentUser, newCompany);
  }

  async function selectDefault(uid: string, companyId: string, oldCompanyId?: string) {
    if (!currentUser) return;
    try {
      showLoading();
      await setCompanyDefault(uid, companyId, oldCompanyId);
      await setCurrentValues(currentUser);
    } catch (error) {
      showMessage('AN_ERROR_OCURRED', 'error');
    } finally {
      dismissLoading();
    }
  }

  const value = {
    currentCompany,
    currentUser,
    logIn,
    logOut,
    signUp,
    signUpWithGoogle,
    signInWithGoogle,
    initializeAdmin,
    setCurrentUser,
    setCurrentCompany,
    switchCompany,
    selectDefault,
    isAuthenticated,
    setIsAuthenticated,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
