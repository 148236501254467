import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Store, Settings } from '@mui/icons-material';
import { IconButton, MenuItem, Typography } from '@mui/material';

import Text from 'src/components/Text';
import { useAuth } from 'src/contexts/auth.context';
import { useLanguage } from 'src/contexts/language.context';

const initialsRegex = /\b(\w)/g;

const routes = [
  {
    name: 'HOME',
    route: '/dashboard',
    onlyAdmin: true,
  },
  {
    name: 'EVENTS',
    route: '/dashboard/events',
    onlyAdmin: false,
  },
  {
    name: 'USERS',
    route: '/dashboard/users',
    onlyAdmin: true,
  },
  // TODO: Uncomment when the companies section has already implemented
  // {
  //   name: 'Companies',
  //   route: '/dashboard/new-company',
  //   onlyAdmin: false,
  // },
];

function NavBar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { getTranslation } = useLanguage();

  const companies = auth?.currentUser?.companies;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e: any) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSignOut = async () => {
    if (!auth?.logOut) return;

    await auth.logOut();
    auth.setIsAuthenticated(false);
    navigate('/');
  };

  const getClass = ({ isActive }: { isActive: boolean }): string => {
    let className = [
      'z-50',
      'flex items-center text-base font-normal',
      '!font-bold',
      'hover:bg-blue-400',
      'hover:text-gray-200',
      'px-3 py-2',
      'md:rounded-lg',
    ];
    if (isActive) className.push('bg-blue-400', 'text-white', 'lg:text-white', 'lg:opacity-100');
    else className.push('xl:text-white text-gray-700 md:text-gray-200');
    return className.join(' ');
  };

  const onSetDefaultCompany = async () => {
    if (auth?.currentCompany?.default) return;
    if (!auth?.currentUser?.uid || !auth?.currentCompany?.id) return;
    const company = companies?.find(item => item.default);
    await auth.selectDefault(auth?.currentUser?.uid, auth?.currentCompany?.id, company?.id);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="!z-50">
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <div className="gap-2">
                {routes.map((item, index) =>
                  !item.onlyAdmin || auth?.currentUser?.isAdmin ? (
                    <NavLink to={item.route} key={index} className={getClass} onClick={handleCloseNavMenu} end>
                      <Text simple>{item.name}</Text>
                    </NavLink>
                  ) : null,
                )}
              </div>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="gap-2">
            {routes.map((item, index) =>
              !item.onlyAdmin || auth?.currentUser?.isAdmin ? (
                <NavLink to={item.route} key={index} className={getClass} onClick={handleCloseNavMenu} end>
                  <Text simple>{item.name}</Text>
                </NavLink>
              ) : null,
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={getTranslation('SETTINGS')}>
              <button onClick={handleOpenUserMenu}>
                <div className="w-10 h-10 rounded-full bg-gray-700 text-white flex items-center justify-center text-2xl">
                  <span className="font-bold text-inherit">
                    {auth?.currentCompany?.name.match(initialsRegex)?.slice(0, 1)}
                  </span>
                </div>
              </button>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {companies?.map(company => (
                <MenuItem
                  key={company.id}
                  onClick={() => {
                    navigate('/dashboard');
                    auth?.switchCompany(company);
                    handleCloseUserMenu();
                  }}
                  className={`${company.id === auth?.currentCompany?.id ? '!bg-gray-200' : ''}`}
                >
                  <div className="w-10 h-10 rounded-full bg-gray-700 text-white flex items-center justify-center text-2xl mr-2">
                    <span className="font-bold text-inherit">{company.name.match(initialsRegex)?.slice(0, 1)}</span>
                  </div>
                  <Typography textAlign="center">{company.name}</Typography>
                </MenuItem>
              ))}
              <Divider />
              {companies && companies?.length > 1 && (
                <MenuItem
                  onClick={onSetDefaultCompany}
                  className={`${auth?.currentCompany?.default ? '!bg-gray-200' : ''}`}
                >
                  <ListItemIcon>
                    <Store fontSize="small" />
                  </ListItemIcon>
                  <Text simple>
                    {auth?.currentUser?.companies?.find(c => c.id === auth?.currentCompany?.id)?.default
                      ? 'DEFAULT'
                      : 'PREDETERMINE'}
                  </Text>
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  navigate('/dashboard/settings');
                  handleCloseUserMenu();
                }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                <Text simple>SETTINGS</Text>
              </MenuItem>
              <MenuItem onClick={handleSignOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <Text simple>LOGOUT</Text>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
