import payments from 'src/assets/images/payments.png';
import Text from 'src/components/Text';
import Languages from 'src/constants/languages';

const PaymentCard = ({ eventLanguage }: { eventLanguage?: Languages }) => {
  return (
    <div className="sm:text-base text-xs w-full md:w-96 sm:h-56 h-39 mb-[-130px] mt-10 md:mt-20  bg-red-100 rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110">
      <div className="h-full">
        <img
          className="relative object-cover w-full h-full rounded-xl"
          src="https://i.imgur.com/kGkSg1v.png"
          alt="bakground-card"
        />
      </div>

      <div className="w-full sm:px-8 px-4 absolute sm:top-8 top-2  pb-4 flex flex-col h-full gap-4">
        <div className="flex justify-between gap-4">
          <div className="">
            <Text className="font-light !text-white" language={eventLanguage}>
              NAME
            </Text>
            <p className="sm:font-medium tracking-widest">---------</p>
          </div>
          <img className="w-14 sm:w-36 sm:h-16" src={payments} alt="payment-methods" />
        </div>
        <div className="">
          <Text className="font-light !text-white" language={eventLanguage}>
            CARD_NUMBER
          </Text>
          <p className="font-medium tracking-more-wider">#### #### #### ####</p>
        </div>
        {/* <div className="sm:pt-5 pt-4 pr-6"> */}
        <div className="pr-6 flex justify-between">
          <div className="">
            <Text className="font-light text-xs !text-white" language={eventLanguage}>
              VALID
            </Text>
            <p className="font-medium tracking-wider text-sm">__/__</p>
          </div>
          <div className="">
            <Text className="font-light text-xs !text-white" language={eventLanguage}>
              EXPIRY
            </Text>
            <p className="font-medium tracking-wider text-sm">__/__</p>
          </div>

          <div className="ml-12">
            <Text className="font-light text-xs !text-white" language={eventLanguage}>
              CVV
            </Text>
            <p className="font-bold tracking-more-wider text-sm">···</p>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default PaymentCard;
