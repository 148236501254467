const system = {
  appVersion: `v 1.0`,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  firebaseFunctionsBaseUrl: process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  apiURL: process.env.REACT_APP_API_URL,
  apiKeysURL: process.env.REACT_APP_API_KEYS_URL,
  publicUrl: process.env.REACT_APP_PUBLIC_URL,
  adminUrl: process.env.REACT_APP_ADMIN_URL,
  tinyMCE_ApiKey: process.env.REACT_APP_TINYMCE_API_KEY,
};

export default system;
