import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import system from 'src/constants/system';

const initialization = () => {
  const firebaseConfig = {
    apiKey: system.apiKey,
    authDomain: system.authDomain,
    databaseURL: system.databaseURL,
    projectId: system.projectId,
    storageBucket: system.storageBucket,
    messagingSenderId: system.messagingSenderId,
    appId: system.appId,
    measurementId: system.measurementId,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  getDatabase(app);
};

export default initialization;
