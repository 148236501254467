import React from 'react';
import { CircularProgress } from '@mui/material';
import { IMetaData } from 'src/types/event.type';

interface IProps {
  show: boolean;
  email: string;
  amount: number;
  description: string;
  extraData?: IMetaData;
}

const DLocalPayment = ({ amount, email, description, show }: IProps) => {
  const loading = true;

  if (!show) return null;

  return (
    <>
      <section id="dlocal-payment-section" className="relative min-h-[500px]">
        {loading ? (
          <div className="flex absolute bg-gray-200 bg-opacity-50 rounded-lg top-0 w-full h-full">
            <CircularProgress size={35} className="m-auto animate-spin" color="primary" />
          </div>
        ) : null}
      </section>
    </>
  );
};

export default DLocalPayment;
