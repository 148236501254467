import React from 'react';
import Languages from 'src/constants/languages';
import { useLanguage } from 'src/contexts/language.context';

interface ITextProps {
  children: string;
  className?: string;
  bold?: boolean;
  italic?: boolean;
  simple?: boolean;
  options?: any;
  color?: string;
  joinCharacters?: string;
  language?: Languages;
}

const Text = ({
  children,
  className = '',
  bold = false,
  italic = false,
  simple = false,
  options = null,
  color = '',
  joinCharacters,
  language,
}: ITextProps) => {
  const { getTranslation } = useLanguage();

  if (simple) return <p className={className}>{getTranslation(children, options, language)}</p>;

  let aClassNames = [];

  aClassNames.push(color ? color : 'text-gray-700');

  if (italic) aClassNames.push('italic');
  if (bold) aClassNames.push('font-bold');
  if (className) aClassNames.push(className);

  return (
    <p className={aClassNames.join(' ')}>
      {getTranslation(children, options, language)} {joinCharacters}
    </p>
  );
};

export default Text;
