import axios from 'axios';
import promise from 'promise';
import system from 'src/constants/system';

var axiosInstance = axios.create({
  baseURL: system.apiURL,
});

axiosInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error?.response?.data) return promise.reject(error.response.data);
    return promise.reject(error);
  },
);

export default axiosInstance;
