import { createContext, useContext } from 'react';

import { useAuth } from 'src/contexts/auth.context';

import { ISecret } from 'src/types/secrets.type';
import { updateMercadoPagoSecret } from 'src/services/database/secret.service';
import { updatePaymentHasSecret } from 'src/services/database/company.service';
import PaymentMethods from 'src/types/payment.type';

interface ISecretContext {
  createMercadoPagoSecret: (secret: string) => Promise<{ status: boolean; message: string }>;
}

const SecretContext = createContext<ISecretContext | null>(null);

export function useSecretContext(): ISecretContext | null {
  return useContext(SecretContext);
}

export function SecretsProvider({ children }: any) {
  const auth = useAuth();

  async function createMercadoPagoSecret(secret: string): Promise<{ status: boolean; message: string }> {
    if (auth) {
      const { currentUser } = auth;
      if (currentUser?.companies && auth?.currentCompany?.id) {
        const secretData: ISecret = {
          secret,
          companyId: auth.currentCompany.id,
        };
        const result = await updateMercadoPagoSecret(secretData);

        if (result.status) {
          await updatePaymentHasSecret(auth.currentCompany.id, PaymentMethods.mercadopago);
        }

        return result;
      }
    }
    return {
      status: false,
      message: 'UNAUTHORIZED',
    };
  }

  const value = {
    createMercadoPagoSecret,
  };

  return <SecretContext.Provider value={value}>{children}</SecretContext.Provider>;
}
