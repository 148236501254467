import axiosInstance from 'src/services/api/interceptors/api.interceptor';
import { IPaymentMethod } from './interfaces';

const SubPath = '/payments/';

export const apiCallSendPayment = (type: string, data: any) => {
  return axiosInstance.post(`${SubPath}create/${type}`, { ...data, client: 'qrmanager' });
};

export const apiCallGetPaymentMethods = (company_id: string) => {
  return axiosInstance.get<IPaymentMethod[]>(`${SubPath}payment-methods?client=qrmanager&company_id=${company_id}`);
};
