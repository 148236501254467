import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';

import English from 'src/assets/languages/English';
import Spanish from 'src/assets/languages/Spanish';

import Languages from 'src/constants/languages';
import { updateUser } from 'src/services/database/user.service';
import { useAuth } from './auth.context';

interface ILanguage {
  language: Languages;
  getTranslation: (key: string, options?: any, localLanguage?: Languages) => string;
  changeLanguage: (language: Languages) => void;
}
const localStorageLanguage = localStorage.getItem('language') as Languages;
const initialState: ILanguage = {
  language: localStorageLanguage
    ? localStorageLanguage
    : window.navigator.language === 'en-US'
    ? Languages['en']
    : Languages['es'],
  getTranslation: () => '',
  changeLanguage: () => {},
};

const LanguageContext = createContext<ILanguage | null>(initialState);

export function useLanguage(): ILanguage {
  return useContext(LanguageContext) as ILanguage;
}

type State = {
  language: Languages;
};

type Action = { type: 'change_language'; payload: Languages };

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'change_language':
      return { ...state, language: action.payload };
    default:
      return state;
  }
}

export function LanguageProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const [{ language }, dispatch] = useReducer(reducer, initialState);

  const getTranslation = (key: string, options: any = null, localLanguage?: Languages): string => {
    if (!key) return '';
    let translate = key;
    const translationLanguage = localLanguage ?? language;
    switch (translationLanguage) {
      case Languages.es:
        translate = Spanish[key] || key;
        break;
      case Languages.en:
      default:
        translate = English[key] || key;
        break;
    }
    if (!options) return translate;
    for (const sOptionKey in options) {
      if (Object.hasOwnProperty.call(options, sOptionKey))
        translate = translate.replace(`{{${sOptionKey}}}`, options[sOptionKey]);
    }
    return translate;
  };

  const changeLanguage = async (language: Languages) => {
    dispatch({ type: 'change_language', payload: language });
    localStorage.setItem('language', language);
    auth?.currentUser && (await updateUser(auth.currentUser.uid, undefined, undefined, undefined, undefined, language));
    auth?.currentUser && auth?.setCurrentUser({ ...auth?.currentUser, language });
  };

  useEffect(() => {
    if (auth?.currentUser && auth?.currentUser.language !== language)
      updateUser(auth.currentUser.uid, undefined, undefined, undefined, undefined, language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        getTranslation,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
