import React, { useState } from 'react';
import { Close } from '@mui/icons-material';

import Text from 'src/components/Text';
import DLocalPayment from 'src/components/Payment/DLocalPayment';
import MercadoPagoPayment from 'src/components/Payment/mercadopago/MercadoPagoPayment';
import PaymentMethods, { PaymentStatusResponse, PaymentTypes } from 'src/types/payment.type';
import system from 'src/constants/system';
import PaymentCard from './PaymentCard';
import { IMetaData } from 'src/types/event.type';
import Languages from 'src/constants/languages';

interface IProps {
  email: string;
  amount: number;
  type: PaymentTypes;
  onClose: (status?: PaymentStatusResponse) => void;
  extraData?: IMetaData;
  mercadopagoToken?: string;
  eventLanguage?: Languages;
}

const Payment = ({ amount, email, onClose, extraData, type, eventLanguage, mercadopagoToken }: IProps) => {
  const [method, setMethod] = useState(PaymentMethods.mercadopago);
  const [status, setStatus] = useState<PaymentStatusResponse | undefined>(undefined);

  const getTabClass = (tabMethod: PaymentMethods) => {
    let className = 'text-center w-1/2 py-1 font-bold transition-all duration-300';
    return className + (method === tabMethod ? ' bg-blue-500 text-white' : '');
  };

  const onSubmit = (status: PaymentStatusResponse) => setStatus(status);
  const modal = extraData?.buyers_info ? '' : 'fixed bg-gray-800 bg-opacity-60';
  return (
    <main className={`w-full h-full top-0 left-0 flex ${modal} !z-[99]  items-center justify-center`}>
      <span
        onClick={() => onClose(status || PaymentStatusResponse.cancelled)}
        className="absolute top-10 right-10 text-2xl text-white rounded-full cursor-pointer flex justify-center items-center"
      >
        <Close fontSize="inherit" className="p-0" />
      </span>
      <div className="flex flex-col w-full md:max-w-[750px]">
        <div className="flex justify-center">
          <PaymentCard eventLanguage={eventLanguage} />
        </div>
        <section className=" pt-24 pb-3 rounded-lg md:min-w-[750px] my-12">
          {/* Only show it on development until we have more payment gateways available */}
          {system.environment === 'development' && !status ? (
            <div>
              <Text className="text-center text-sm mb-2" language={eventLanguage}>
                SELECT_PAYMENT_GATEWAY
              </Text>
              <section className="flex flex-row border-blue-500 border-[1px] rounded-full text-blue-500 overflow-hidden cursor-pointer mb-5">
                <div
                  className={getTabClass(PaymentMethods.mercadopago)}
                  onClick={() => setMethod(PaymentMethods.mercadopago)}
                >
                  MercadoPago
                </div>
                <div className={getTabClass(PaymentMethods.dlocal)} onClick={() => setMethod(PaymentMethods.dlocal)}>
                  dLocal
                </div>
              </section>
            </div>
          ) : null}
          <section className="relative">
            {method === PaymentMethods.mercadopago ? (
              <MercadoPagoPayment
                type={type}
                email={email}
                amount={amount}
                onSubmit={onSubmit}
                extraData={extraData}
                description="QR Event Manager payment"
                show={method === PaymentMethods.mercadopago}
                mercadopagoToken={mercadopagoToken}
                eventLanguage={eventLanguage}
              />
            ) : null}
            <DLocalPayment
              email={email}
              amount={amount}
              extraData={extraData}
              show={method === PaymentMethods.dlocal}
              description="QR Event Manager subscription"
            />
          </section>
          {status ? (
            <button className="bg-blue-400 w-full py-3 rounded-xl text-white font-bold" onClick={() => onClose(status)}>
              <Text simple language={eventLanguage}>
                CLOSE
              </Text>
            </button>
          ) : null}
        </section>
      </div>
    </main>
  );
};

export default Payment;
