import React, { useEffect, useState } from 'react';
import Text from 'src/components/Text';
import system from 'src/constants/system';
import { useAuth } from 'src/contexts/auth.context';
import { useCompanyContext } from 'src/contexts/company.context';
import { useLanguage } from 'src/contexts/language.context';
import { useSecretContext } from 'src/contexts/secret.context';
import { ICompany } from 'src/types/company.type';
import PaymentMethods from 'src/types/payment.type';

const SECRET_PASSWORD = 'secret_password';
export const MercadoPagoKeys = ({ company, getData }: { company: ICompany | null; getData: () => void }) => {
  const { getTranslation } = useLanguage();
  const companyContext = useCompanyContext();
  const secretContext = useSecretContext();
  const auth = useAuth();

  const [mercadoPagoKeys, setMercadoPagoKeys] = useState<{ secret: string; token: string }>({
    secret: company?.payments?.mercadopago?.hasSecret ? SECRET_PASSWORD : '',
    token: company?.payments?.mercadopago?.token || '',
  });

  useEffect(() => {
    if (company) {
      setMercadoPagoKeys({
        secret: company?.payments?.mercadopago?.hasSecret ? SECRET_PASSWORD : '',
        token: company?.payments?.mercadopago?.token || '',
      });
    }
  }, [company]);

  const handleChangeClick = async () => {
    if (mercadoPagoKeys.secret && mercadoPagoKeys.secret !== SECRET_PASSWORD) {
      await secretContext?.createMercadoPagoSecret(mercadoPagoKeys.secret);
    }

    if (mercadoPagoKeys.token) {
      await companyContext?.addPaymentMethodToken(mercadoPagoKeys.token, PaymentMethods.mercadopago);
    }

    getData();
  };

  return (
    <div className="mb-8">
      <Text bold className="mb-5">
        MERCADO_PAGO_KEYS
      </Text>
      {system.environment !== 'production' && auth?.currentUser?.isOwner && (
        <>
          <div className="flex flex-col md:flex-row gap-5 mb-3">
            <div className="flex-1">
              <Text className="mb-1">SECRET</Text>
              <input
                type={
                  company?.payments?.mercadopago?.hasSecret && mercadoPagoKeys.secret === SECRET_PASSWORD
                    ? 'password'
                    : 'text'
                }
                value={mercadoPagoKeys.secret}
                placeholder={getTranslation('SECRET')}
                onChange={({ target: { value } }) => setMercadoPagoKeys(current => ({ ...current, secret: value }))}
                className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
              />
            </div>
            <div className="flex-1">
              <h3 className="mb-1">Token</h3>
              <input
                value={mercadoPagoKeys.token}
                placeholder="Token"
                onChange={({ target: { value } }) => setMercadoPagoKeys(current => ({ ...current, token: value }))}
                className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
              />
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none text-right">
            <button
              onClick={handleChangeClick}
              className="bg-blue-600 px-3 py-1 rounded-md text-white font-bold transition-all duration-500"
            >
              <Text simple>CHANGE</Text>
            </button>
          </div>
        </>
      )}
    </div>
  );
};
