import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'src/contexts/auth.context';

export const PrivateRoute = () => {
  const auth = useAuth();
  return auth?.currentUser ? <Outlet /> : <Navigate to="/login" />;
};

export const AdminRoute = () => {
  const auth = useAuth();
  return auth?.currentUser?.isAdmin ? <Outlet /> : <Navigate to="/dashboard/events" />;
};
