import React from 'react';
import Logo from 'src/assets/images/logo-footer.png';
import Arrow from 'src/assets/images/arrow.svg';
import { useLanguage } from 'src/contexts/language.context';
import Text from 'src/components/Text';
import { useAuth } from 'src/contexts/auth.context';

function Footer() {
  const { getTranslation } = useLanguage();
  const auth = useAuth();
  return (
    <footer className="bg-white">
      <div className="container mx-auto px-8">
        <div className="w-full flex flex-col md:flex-row py-6">
          <div className="flex-1 mb-6 text-black">
            <img
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              alt="QR Event Manager"
              className="md:w-4/5 text-2xl lg:text-4xl cursor-pointer"
              src={Logo}
            ></img>
          </div>

          <div className="flex-1 mb-5 md:mb-0">
            <p className="uppercase text-gray-500 ml-2 md:mb-6">Social</p>
            <div className="flex flex-row">
              <img className="mt-2" src={Arrow} alt="arrow"></img>
              <ul className="list-reset ">
                <li className="footer-link mt-2 inline-block mr-2 md:block md:mr-0">
                  <a href="https://uy.linkedin.com/company/vangwe" className="no-underline hover:underline">
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-1">
            <Text color="text-gray-500" className="uppercase md:mb-6">
              COMPANY
            </Text>

            <ul className="footer-link list-reset mb-6">
              <div className="flex flex-row">
                <img className="mt-2" src={Arrow} alt="arrow"></img>
                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href="https://www.vangwe.com"
                    className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    {getTranslation('ABOUT_US')}
                  </a>
                </li>
              </div>
              <div className="flex flex-row">
                <img className="mt-2" src={Arrow} alt="arrow"></img>
                <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                  <a
                    href={auth?.isAuthenticated ? '/contact' : '#contact'}
                    className="no-underline hover:underline text-gray-800 hover:text-pink-500"
                  >
                    {getTranslation('CONTACT')}
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
