import { Helmet } from 'react-helmet-async';

export type SEOType = {
  description: string;
  title: string;
  keywords: string;
  children?: React.ReactNode;
};
export default function SEO({ description, title, keywords, children }: SEOType) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {children}
    </Helmet>
  );
}
