import { child, getDatabase, ref, update, get } from 'firebase/database';
import { ISecret } from 'src/types/secrets.type';

export const updateMercadoPagoSecret = async (secretData: ISecret): Promise<{ message: string; status: boolean }> => {
  try {
    await update(child(ref(getDatabase()), `secrets/${secretData.companyId}`), {
      mercadopagoSecret: secretData.secret,
    });
    return { status: true, message: 'Mercado pago secret created successfully' };
  } catch (err: any) {
    return { status: false, message: 'An error occurred while creating an Mercado Pago secret' };
  }
};
