import { StateCreator } from 'zustand';
import { TStore } from './index';

export type TLoading = {
  isLoading: boolean;
  showLoading: () => void;
  dismissLoading: () => void;
};

const createLoadingSlice: StateCreator<TStore, [], [], TLoading> = set => ({
  isLoading: false,
  showLoading: () =>
    set(state => ({
      isLoading: true,
    })),
  dismissLoading: () =>
    set(state => ({
      isLoading: false,
    })),
});

export default createLoadingSlice;
