enum PaymentMethods {
  mercadopago = 'mercadopago',
  dlocal = 'dlocal',
}

export default PaymentMethods;

export enum PaymentStatusResponse {
  error = 'error',
  success = 'success',
  pending = 'pending',
  cancelled = 'cancelled',
}

export enum PaymentTypes {
  subscription = 'subscription',
  payment = 'payment',
}

export enum PaymentStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  refunded = 'refunded',
  cancelled = 'cancelled',
  authorized = 'authorized',
  in_process = 'in_process',
  in_mediation = 'in_mediation',
  charged_back = 'charged_back',
}
