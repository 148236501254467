import { useEffect, useState } from 'react';

import { useLanguage } from 'src/contexts/language.context';

interface ITip {
  title: string;
  suggestion: string;
}
interface ISuggestion {
  type: string;
  amount: number;
}

function Tip({ title, suggestion }: ITip) {
  return (
    <div className="flex items-center text-lg  border-b pb-4 mt-4 md:mt-0 mb-4 border-gray-200 sm:flex-row flex-col">
      <div className="flex-grow sm:text-left text-center mt-2 sm:mt-0">
        <div
          className="text-gray-900 text-lg title-font font-medium mb-2"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div dangerouslySetInnerHTML={{ __html: suggestion }} />
      </div>
    </div>
  );
}

export default function Suggestions({ type, amount }: ISuggestion) {
  const [data, setData] = useState<ITip[]>([]);

  const { getTranslation } = useLanguage();
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getData() {
    let i = 1;
    const arrayCopy = [];
    for (i; i <= amount; i++) {
      const obj = {
        title: `T_${type.toUpperCase()}_SUGGESTION_${i}`,
        suggestion: `A_${type.toUpperCase()}_SUGGESTION_${i}`,
      };
      arrayCopy.push(obj);
    }
    setData(arrayCopy);
    return data;
  }
  return (
    <div id="suggestions" className="text-gray-600 bg-white text-xs px-3">
      {data.map((value, key) => (
        <Tip key={key} title={getTranslation(value.title)} suggestion={getTranslation(value.suggestion)}></Tip>
      ))}
    </div>
  );
}
