const English: { [key: string]: string } = {
  SIGN_IN: 'Sign In',
  FORGOT_PASSWORD: 'Forgot password?',
  NOT_HAVE_ACCOUNT: "Don't have an account? Sign up",
  SIGN_IN_GOOGLE: 'Sign in with Google',
  WELCOME_MESSAGE: 'Welcome {{value}}',
  EMAIL: 'Email',
  PASSWORD: 'Password',

  CREATE_EVENT: 'Create event',
  VIEW_EVENTS: 'View events',
  NO_EVENTS_LAST_YEAR: 'No events recorded prior to the current date',

  SIGN_UP: 'Sign Up',
  USER_SIGN_UP: 'User Signup',
  COMPANY_SIGN_UP: 'Company Signup',
  COMPANY_SIGN_UP_MSG: 'You are not associated to any company. To continue create your company',
  SIGN_UP_GOOGLE: 'Sign up with Google',
  ALREADY_HAVE_ACCOUNT: 'Already have an account? Sign in',
  FIRST_NAME: 'First Name',
  YOUR_FIRST_NAME: 'Your first name',
  LAST_NAME: 'Last Name',
  YOUR_LAST_NAME: 'Your last name',
  ENTER_YOUR_NAME: 'Enter your name',
  ENTER_YOUR_EMAIL: 'Enter your email',
  YOUR_EMAIL: 'Your email address',
  YOUR_PASSWORD: 'Your password',
  CONFIRM_PASSWORD: 'Confirm password',
  CONFIRM_YOUR_PASSWORD: 'Confirm your password',
  COMPANY_NAME: 'Company name',
  COUNTRY: 'Country',
  COMPANY_COUNTRY: "Company's country",
  ROLE: 'Role',
  NEXT: 'Next',
  EMAIL_ALREADY_IN_USE: 'Email already in use',
  INVALID_EMAIL: 'Please enter a valid email',
  FIELD_IS_REQUIRED: 'This field is required',
  PASSWORD_CONFIRMATION_NOT_MATCH: 'The password confirmation does not match',
  MINIMUM_CHARACTERS_IS_REQUIRED: 'Minimum {{value}} characters is required',
  MAXIMUN_LENGTH_EXCEEDED: 'Maximum length exceeded',
  FIELD_ACCEPTS_TEXT_ONLY: 'This field accepts text only',
  FIELD_ACCEPTS_NUMBERS_ONLY: 'This field accepts numbers only',
  ENTER_A_VALID_PHONE_NUMBER: 'Please enter a valid phone number. i.e: xxx-xxx-xxxx',
  ENTER_A_VALID_ZIP_CODE: 'Please enter a valid zip code',
  PROVIDE_A_VALID_VALUE: 'Please provide a valid value',
  SELECT_A_VALUE: 'Please select a value',

  EDIT: 'Edit',
  CREATE: 'Create',
  DELETE: 'Delete',
  DUPLICATE: 'Duplicate',
  ADD: 'Add',
  CANCEL: 'Cancel',
  ACCEPT: 'Accept',
  ACTION_CANT_UNDONE: "This action can't be undone",
  DO_YOU_WANT_DELETE_EVENT: 'Do you want to delete {{value}}?',
  EVENT_VIEW: 'Event view',
  EVENTS: 'Events',
  EVENTS_CREATED: 'Events created',
  EVENTS_FINALISED: 'Finished events',
  NEXT_EVENT: 'Next event',
  NO_EVENTS: 'No events',
  USERS: 'Users',
  HOME: 'Home',

  RECOMENDED: 'Recomended',
  EVENT_NOT_FOUND: 'Event not found',
  EVENT_DETAILS: 'Event details',
  NAME: 'Name',
  LOCATION: 'Location',
  EVENT_DESCRIPTION: 'Event description',
  EVENT_IMAGE: 'Event image',
  DESKTOP_IMAGE: 'Desktop image',
  MOBILE_IMAGE: 'Mobile image',
  EVENT_DATE: 'Date',
  EVENT_DATE_TIME: 'Date&Time of the event',
  EVENT_DATE_PAST: 'The event should take place later than when it was created',
  EVENT_DATE_AFTER_TODAY: 'Event date must be after today date',
  READ_FROM_DATE_BEFORE_EVENT_DATE: '"Read from" date must be before event date',
  READ_FROM_DATE_BEFORE_READ_TO: '"Read from" date must be before "read to" date',

  EVENT_DATE_ERROR: 'You should be able to scan QR before the event begins',
  EVENT_DATE_LATER: 'This date should be later than the allowed time for scanning',
  ALLOW_TO_READ_QR_FROM: 'Allow to read QR from',
  ALLOW_TO_READ_QR_UNTIL: 'Allow to read QR until',
  SPECIAL_CONDITIONS: 'Special conditions',
  SPECIAL_CONDITIONS_EXAMPLE: '(e.g.: Older than 18, formal dressing, etc)',
  SOCIAL_LINKS: 'Social networks',
  IMAGE_LINK: 'Image link',
  EVENT_LANGUAGE: 'Event lenguage',

  WHEN: 'When',
  WHERE: 'Where',
  WHERE_LOCATION_EVENT: 'Where should I go?',
  QR_SCANNER: 'QR Scanner',
  SCAN_NOT_ALLOWED: 'Scan not allowed',
  EARLY_SCAN: 'Too early to read accesses',
  LATE_SCAN: 'Too late to read accesses',
  ALREADY_SCANNED: 'Already scanned',
  NOT_VALID: 'QR code not valid',
  REQUEST_PERMISSION: 'Request permission',
  SCAN_QR: 'Scan the QR',
  SCAN_QR_MESSAGE: 'Make sure your QR is on a white background',
  SUCCESS_SCAN: 'Success scan',
  CAPTURE_NEW_ACCESS: 'Capture new access',
  FAILED_TO_SCAN: 'Failed to scan',

  EVENT_PUBLIC_URL: 'Public URL',
  COMPANY_PUBLIC_URL: 'Public URL',
  EVENT_PUBLIC_URL_COPY_SUCCESS: 'URL copied to clipboard!',
  EVENT_PUBLIC_URL_COPY_ERROR: 'There was an error trying to copy the public URL',

  ACCESSES: 'Accesses',
  ACCESS_ADD: 'Add access',
  TOTAL_ACCESSES: 'Total accesses',
  SCANNED_ACCESSES: 'Total scanned accesses',
  ACCESS_INFO:
    'Find out how to get statistics of your event and generate the QR codes for your accesses by reading the FAQs',
  STATISTICS: 'Statistics',
  REVENUE: 'Sells',
  NUMBER_ACCESES: '# Accesses',
  TIME_ACCESES: 'Schedule of the scanned accesses',
  PRICE_ACCESES: 'Price of scanned accesses',
  TICKET_BUY_ON: 'Ticket buy on',
  FILTER: 'Filter',
  DOCUMENT: 'Document',
  READ: 'Read',
  NO_RECORDS: 'No records',
  READ_DATE: 'Read date',
  READ_BY: 'Read by',
  ROWS_PER_PAGE: 'Rows per page',
  SELECTED: 'Selected',
  ACCESS_REMOVE: 'Remove access/es',
  ACCESS_REMOVE_MESSAGE: 'Are you sure to remove {{value}} access/es?',
  ACCESSES_LOAD_DESCRIPTION:
    'Load the accesses in CSV format, with up to 5 columns: document and name are mandatory. <a href="/faqs" target="_blank" class="hover:text-blue-100 cursor-pointer ease-in-out">Here</a> you can find more usefull tips',
  IMAGE_LOAD_DESCRIPTION: 'Load your image. Accepted extensions are .png, .jpeg and .bmp',
  UPLOAD_FILE: 'Upload file',
  GENDER_ERROR: 'Gender field value must be: Female (or F), Male (or M) or Other (or O)',
  PREVIEW: 'Preview',
  LANGUAGE: 'Language',
  COMPANY_LANGUAGE: 'Company language',
  PREDETERMINE: 'Predetermine',
  DEFAULT: 'Default',
  EMAIL_VERIFICATION_SENT: 'A verification email was sent to you!',
  SEND_EMAIL_PASSWORD: 'Send reset password request',
  FORGOT_PASSWORD_MESSAGE:
    'Enter the email address associated with your account and we will send you a link to reset your password',
  DUPLICATE_DOCUMENT_ON_ACCESS: 'Already exist an access with the specified document',
  ALREADY_EXIST_UNIQUE_VALUE: 'An access with the specified unique value already exists',
  MISSING_REQUIRED_FIELDS_ON_ACCESSES: 'Missing required fields on accesses',
  COMPANY_IMAGE: 'Company image',
  SHOW_ONLY_BANNER: 'Show only banner',
  SUCCESS_UPDATE: 'Update successful',
  FAILED_UPDATE: 'Update failed',
  SEND_EMAIL: 'Send email',
  SUCCESS_EMAIL_SEND: 'Success email send',
  FAILED_EMAIL_SEND: 'Failed email send',
  EMAIL_SENT: 'Email sent',

  INVITE: 'Invite',
  REMOVE: 'Remove',
  USERS_REMOVE: 'Remove user',
  USERS_REMOVE_MESSAGE: 'Are you sure to delete the company user?',

  INVALID_CREDENTIALS: 'Invalid credentials',
  YOU_MUST_FIRST_CREATE_ACCOUNT: 'You must first create an account',
  AN_ERROR_OCURRED: 'An error occurred, please try again later',
  UNAUTHORIZED: 'Unauthorized',

  USERS_CANNOT_REMOVE_ADMIN: 'Cannot remove an admin user',
  USERS_SUCCESS_REMOVE: 'User eliminated successfully',

  BACK: 'Back',
  LOGOUT: 'Logout',
  SUBMIT: 'Submit',
  SETTINGS: 'Settings',
  MY_PROFILE: 'My profile',
  SAVE: 'Save',
  EXIT: 'Exit company',

  ATTRIBUTE_REQUIRED: 'The "{{value}}" attribute is required',
  ATTRIBUTE_INVALID: '{{value}} is not valid',
  TYPE_REQUIRED: 'You must select a plan',

  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',

  ERROR_INVALID_ACTION: 'Your request has expired or the link has already been used',
  ERROR_GENERAL: 'Something went wrong',
  TRY_AGAIN: 'Try again',
  PASSWORD_CHANGED: 'Password successfully changed!',
  ERROR_PASSWORD: "Passwords don't match",
  ERROR_LENGTH: 'Minimum is {{value}} characters',
  RESET_PASSWORD_MSG: 'Reset password for {{value}}',
  EMAIL_VERIFIED: 'Email successfully verified!',

  SPANISH: 'Spanish',
  ENGLISH: 'English',

  CONTACT_SENT: 'Your message has been successfully sent. We will contact you as soon as possible.',
  FIELDS_REQUIRED: 'Please complete all fields.',
  VERIFY_EMAIL: 'Please verify email input.',
  CARD_FIELDS_REQUIRED: 'Please complete card fields',
  CARD_NUMBER: 'Card number',
  EXPIRATION_DATE: 'Expiration date',
  SECURITY_CODE: 'Security code',
  CARD_HOLDER: 'Card holder',
  IDENTIFICATION_TYPE: 'Type of documentation',
  INSTALLMENTS: 'Installments',
  CARD_ISSUER: 'Card issuer',
  IDENTIFICATION_NUMBER: 'Document number',
  PAY: 'Pay',

  MAX_NUMBER_COLS: 'Max number of columns is {{value}}',

  FEMALE: 'Female',
  MALE: 'Male',
  OTHER: 'Other',

  STATICS: 'Statics',
  DETAILS: 'Details',

  ENTER: 'Sign in',
  OR: 'or',
  REGISTER: 'Sign up',
  NOW: 'Now',
  CONTACT: 'Contact',
  PRODUCT: 'Product',
  FEATURES: 'Features',
  HERO_HOME_SUBTITLE: 'Create events and scan in a safe and simple way!',
  HERO_HOME_TITLE: 'Welcome to Event Manager!',
  FEATURES_TITLE: 'Features',
  HERO_HOME_CREATE_TITLE: 'Managing your events was never so easy!',
  HERO_HOME_CREATE_SUBTITLE: 'Manage your events in 4 simple steps',
  HERO_EVENTS_DETAILS: 'EVENTS DETAILS',
  HERO_USER_DETAILS: 'USER DETAILS',
  HERO_CONTACT_US: 'Contact us to know more!',
  FEATURES_SUBTITLE: 'Manage your events easily without worrying about security',
  FEATURES_EASY_TITLE: 'Easy to use',
  FEATURES_EASY_SUBTITLE: 'We make it simple',
  FEATURES_MULTIDEVICE_TITLE: 'Multi-device',
  FEATURES_MULTIDEVICE_SUBTITLE: 'Accessible from any smart device',
  FEATURES_ALCANCE_TITLE: 'Reach',
  FEATURES_ALCANCE_SUBTITLE: 'Access from anywhere',
  FEATURES_TECHNOLOGIES_TITLE: 'Cutting-edge technologies',
  FEATURES_TECHNOLOGIES_SUBTITLE: 'Our system was developed with trending technologies',
  FEATURES_SUPPORT_TITLE: 'Support',
  FEATURES_SUPPORT_SUBTITLE: 'Keep a direct line with our support team',
  FEATURES_SECURITY_TITLE: 'Security',
  FEATURES_SECURITY_SUBTITLE: 'Your data is our main concern and will be handled in a sensible way',
  FLOW_REGISTER_CONTENT: 'Register now at no cost',
  FLOW_CREATE_TITLE: 'Create your company',
  FLOW_CREATE_CONTENT: 'Create your company profile and add your events',
  FLOW_GENERATE_TITLE: 'Generate events',
  FLOW_GENERATE_CONTENT: 'Generate events and create accesses for your guests',
  FLOW_SCAN_TITLE: 'Scan',
  FLOW_SCAN_CONTENT: 'Scan the codes and get accurate data for the time your guest arrived',
  COMPANY: 'Company',
  ABOUT_US: 'About us',
  ADDRESS: 'Address',
  PHONE: 'Phone',
  CONTACT_US: 'Contact us',
  CONTACT_US_MSG: 'Leave us your comments and we will contact you shortly',
  FULL_NAME: 'Your full name',
  MESSAGE: 'Message',
  MESSAGE_DESCRIPTION: 'Leave your message/suggestion',
  WELL_GET_BACK: "We'll get back to you shortly",
  SEND_MESSAGE: 'Send message',

  METHOD_NOT_IMPLEMENTED: 'Method not implemented',

  EXIT_COMPANY_SUCCESS: 'You have successfully left the company',
  EXIT_COMPANY_ERROR: 'An error occurred while exiting the company',

  LENGHT_REACHED: 'You already have 5 columns, please follow that access format.',
  DOCUMENT_FIELD_REQUIRED: 'You must have document access field none empty',
  NAME_FIELD_REQUIRED: 'You must have name access field none empty',
  ACCESS_ALERT_TITLE: 'Adding new columns?',
  ACCESS_ALERT_TEXT: ' You are adding new columns and it will generate empty fields',
  DISAGREE: 'Disagree',
  AGREE: 'Agree',
  EMPTY_FILE: 'You have uploaded an empty file',
  SEND_MAILS_TO_ACCESSES: 'Do you want to send an email to each new access their respective QR code?',
  SEND_MAIL: 'Send emails',
  SEND_MAIL_TO_SINGLE_ACCESS: 'Do you want to send an email to the new access its respective QR code?',

  NO_ACCESS_DATA: 'No access data',
  NO_REVENUE_DATA: 'No selling data',
  NO_GENDER_DATA: 'No gender data',
  ACCESSES_PRICES: 'Access prices',
  NO_PRICES_DATA: 'No price data',

  SUBSCRIPTION: 'Subscription',
  ACTUAL_SUBSCRIPTION: 'Actual subscrition',
  PRICE: 'Price',
  FREE: 'Free',
  CHANGE: 'Change',
  ANNUAL_DISCOUNT: '(10% discount on annual payment)',
  SUBSCRIPTION_SUCCESS_UPDATED: 'Subscription successfully updated',
  SUBSCRIPTION_SUCCESS_CANCELED: 'Subscription successfully canceled',
  CANCEL_SUBSCRIPTION: 'Confirm Cancellation of Subscription',
  CANCEL_SUBSCRIPTION_MSG: 'Are you sure you want to cancel your subscription?',
  CHANGE_SUBSCRIPTION: 'Change Subscription',
  CHANGE_SUBSCRIPTION_MSG: 'Are you sure you want to change your subscription?',

  PROCESSING_REQUEST: 'Processing your request',
  WAIT_MOMENT: 'Wait a moment please',

  INCLUDED_IN_SUBSCRIPTION: '(Included in your subscription)',
  ILIMITED_ACCESSES: 'Ilimited accesses',
  TAGS: 'Event tags',

  EVENT_NOT_EDITABLE: "This event has had access read and it's not editable any more",
  EVENT_NOT_SCANNABLE: "This event has been deleted, you can't scan accesses anymore",

  TOO_MANY_ACCESSES:
    'You are trying to upload more accesses than allowed for this event.You have {{value}} accesses left to add.',
  ACCESSES_SUCCESFULLY_ADDED: 'Accesses added successfully',
  EMPTY_FIELDS: 'You have empty fields',
  FIELD_IS_EMPTY: 'Field "{{value}}" is required',

  SCANN_TIME: 'We will assign a deadline for the event to be modified once you read the first access. ',
  SCANN_TIME_LIMIT: 'You have read the access on {{value}}. From now on you have 7 days to modify the event.',
  DANGER: 'Be careful',

  FILTER_STATUS: 'Filter by status',
  FILTER_STATUS_MOBILE: 'Status',
  FILTER_TAGS: 'Filter by tags',
  FILTER_TAGS_MOBILE: 'Tags',

  Q1: 'How do I create an account? Is it necessary to have a company?',
  Q2: 'What is the format to load the accesses? What considerations should I take into account?',
  Q3: 'How do I get the QR codes for my accesses?',
  Q4: 'Should I download an app to scan the access codes?',
  Q5: 'Can I invite a user who does not have an account in the application to my company?',
  Q6: 'What are the types of plans available and what are their costs?',
  Q7: 'If I contract a subscription, do I lose the 3 free events?',
  Q8: 'What are the payment methods enabled?',

  A1: 'You can create it from the "Register" button located at the beginning. There you must choose if you want to create the account automatically with Google or if you prefer to fill in the required data. It is not necessary to have a company registered in your name, with a company we call a group or community of which you, as the owner, can have control over it and nothing prevents the existence of another company with the same name as yours.',
  A2: 'Accesses must be uploaded in CSV format, with up to 5 columns of which "document" and "name" are mandatory, and 3 more optional. If you use the columns of the template, you will be able to see graphs of these fields. The "gender" column must be filled with the value F for female, M for male, and O otherwise.',
  A3: '<p>You can either generate them in the application or have them generated externally to it.</p><p>Once the accesses are uploaded it is possible to download the list by clicking in the "download accesses" button.</p><p>In the list there will be a column with the QR code for each access.</p>',
  A4: 'In the event description tab you will have a button which allows you to scan the QR from a mobile device without having to download a separate application',
  A5: 'You can invite him to his personal email, but in order to use it, he must log in with the same email to which the notification was sent.',
  A6: 'These are detailed in the "Pricing" section with their respective benefits.',
  A7: 'No, you still have 3 events of 50 free accesses that you can use whenever you want.',
  A8: 'You can pay using Mercado Pago.',

  HAVE_QUESTION: 'Have questions?',
  FAQ: 'Frequently asked questions',
  STILL_HAVE_QUESTION: 'Still have questions?',

  SUGGESTIONS: 'Suggestions',
  TIPS: 'You can find suggestions regarding the operation of the application ',
  HERE: 'here',

  T_USERS_SUGGESTION_1: '<p>Management of users and roles</p>',
  T_USERS_SUGGESTION_2: '<p>User invitations</p>',
  T_USERS_SUGGESTION_3: '<p>Subscriptions or particular plans for events</p>',
  A_USERS_SUGGESTION_1:
    '<p>Keep in mind that you can access different functionalities if you are the owner of the company, if you are a guest or if you are an administrator, we will detail that information below.</p>',
  A_USERS_SUGGESTION_2:
    '<p>If the user does not have an account in the application, we recommend that they check their email address and follow the steps that are detailed in the email to access it. In the event of any inconvenience, they can contact us by filling in <a href="#contact">this</ to> form</p>',
  A_USERS_SUGGESTION_3:
    '<p>We recommend the subscription if it is a user who will use the application regularly, otherwise you can hire a short-term plan. </p>',

  T_EVENTS_SUGGESTION_1: '<p>Event modification deadline</p>',
  T_EVENTS_SUGGESTION_2: '<p>How to get event statistics</p>',
  A_EVENTS_SUGGESTION_1:
    '<p>After the first reading, the event will have a period of <span class="text-blue-700"> 7 days </span> for any of its modifications to be blocked, thus becoming a finished event</p>',
  A_EVENTS_SUGGESTION_2:
    '<p>We offer statistics about the number of accesses to the event grouped by genre as well as the total profit only if you provide the column "gender" and the column "price" at the moment of uploading the accesses, otherwise we also provide the total accesses, the total accesses read, and a graph showing the number of accesses at different times. <br> We believe that all this data will help you with upcoming events.</p>',

  T_ACCESS_SUGGESTION_1: '<p>Security in QR codes</p>',
  T_ACCESS_SUGGESTION_2: '<p>Csv upload format</p>',
  T_ACCESS_SUGGESTION_3: '<p>Generate your QR code with us</p>',
  T_ACCESS_SUGGESTION_4: '<p>Read your externally generated access</p>',
  A_ACCESS_SUGGESTION_1:
    '<p>We recommend that you generate the codes with the highest possible security to avoid identity theft</p>',
  A_ACCESS_SUGGESTION_2:
    '<p>The csv that contains the event accesses must contain the document and name columns, additionally you must take into account that you cannot generate accesses that contain more than 5 columns, that is, it has 3 fields which are optional and customizable. <br>If you load an access with custom fields, all subsequent accesses loaded through another csv will have those fields with their respective values or with empty values if they have not been completed.</p>',
  A_ACCESS_SUGGESTION_3:
    '<p>You can generate your QR code with us, you just have to request it <a href="#">here</a></p>',
  A_ACCESS_SUGGESTION_4:
    '<p>As an example if we have an access associated with the document<span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> 12345678 </span> and name <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> example </span> the qr format should be the following: <span class="bg-gray-300 mt-1 rounded-sm p-1 text-xs shadow font-medium text-black">{"document":"12345678","name":"example"}</span>.</p><br><p>If the access has custom fields such as <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> gender </span> or <span class="bg-gray-300 mr-2 ml-2 rounded-sm p-1 text-xs shadow font-medium text-black"> price </span> these should be added similarly . </p> <br><p class="text-xs text-black font-thin"> Note that gender field value must be: Female (or F), Male (or M) or Other (or O)</p>',

  active: 'Active',
  finished: 'Finished',
  deleted: 'Deleted',
  PUBLIC: 'Public',
  PRIVATE: 'Private',

  DOWNLOAD_CSV: 'Download accesses',

  HELPER_LOCATION: 'For more precision type Street number, City.',
  GEOLOCATION_DENIED_AND_NO_LOCATION: 'You must either provide your location or type an adress on the location field.',

  PAYMENT_PENDING: 'Pending payment',
  PAYMENT_PENDING_MESSAGE: 'Your payment is in process, we will notify you when we have news about it',
  CLOSE: 'Close',

  CC_REJECTED_BAD_FILLED_CARD_NUMBER: 'Please check the card number.',
  CC_REJECTED_BAD_FILLED_DATE: 'Revise the expiration date.',
  CC_REJECTED_BAD_FILLED_OTHER: 'Please review the data.',
  CC_REJECTED_BAD_FILLED_SECURITY_CODE: 'Please check the security code of the card.',
  CC_REJECTED_BLACKLIST: 'We were unable to process your payment.',
  CC_REJECTED_CALL_FOR_AUTHORIZE: 'You must authorize the payment of US$ {{amount}} before the means of payment.',
  CC_REJECTED_CARD_DISABLED:
    'Call your payment method to activate your card or use another payment method. The phone number is on the back of your card.',
  CC_REJECTED_CARD_ERROR: 'We were unable to process your payment.',
  CC_REJECTED_DUPLICATED_PAYMENT:
    'You already made a payment for that value. If you need to pay again, use another card or another means of payment.',
  CC_REJECTED_HIGH_RISK: 'Your payment was declined. Choose another payment method, we recommend cash.',
  CC_REJECTED_INSUFFICIENT_AMOUNT: 'Your payment method does not have sufficient funds.',
  CC_REJECTED_INVALID_INSTALLMENTS: 'Payment method does not process payments on installments.',
  CC_REJECTED_MAX_ATTEMPTS:
    'You have reached the limit of allowed attempts. Choose another card or another means of payment',
  CC_REJECTED_OTHER_REASON: 'Payment method did not process the payment.',

  EVENT_PAYMENT_REJECTED: 'Your payment has been rejected.',
  EVENT_PAYMENT_CANCELLED: 'You have cancelled the payment.',
  EVENT_PAYMENT_PENDING: 'We are processing your payment, we will notify you when we get a response.',
  EVENT_PAYMENT_APPROVED: 'Event purchased on {{date}}',

  WRONG_CSV_FORMAT: 'Please check your csv format because it seems to be wrong.',

  RETRY_PAYMENT: 'Retry payment',

  EXTERNAL_QR_1: 'How should I generate the qr to be read correctly? Answered ',
  EXTERNAL_QR_2: ' in accesses section.',

  COPY_TO_CLIPBOARD: 'Copy to clipboard',
  NO_DATA_AVAILABLE: 'No data available',
  DOWNLOAD: 'Download',

  COMPANY_API_KEYS: 'Api keys',
  COMPANY_API_KEYS_CREATED_SUCCESS: 'Company api Key created successfully',
  COMPANY_API_KEYS_DELETED_SUCCESS: 'Company api Key deleted successfully',
  COMPANY_API_KEYS_DELETED_CONFIRM_VALUE: 'Please type <b>{{value}}</b> to confirm',
  COMPANY_API_KEYS_DELETED_CONFIRM_INFO:
    'This action <b>cannot</b> be undone. This will permanently delete the <b>{{value}}</b> company api key and if you’d like to use it in the future, you will need to create a new one.',
  COMPANY_API_KEYS_TOKEN_COPY_SUCCESS: 'The token was copied to clipboard',
  COMPANY_API_KEYS_TOKEN_COPY_ERROR: 'There was an error trying to copy the token',
  COMPANY_API_KEYS_CREATE_INFO:
    'When you create an company api key, save the token in a secure location. The token is available only at the time you create it. If you lose it, you must delete the company api key and create a new one',
  MERCADO_PAGO_KEYS: 'Mercado pago keys',
  SECRET: 'Secret',

  COMPANY_API_KEYS_REMOVE_DIALOG_TITLE: 'Are you sure to delete the company api key?',

  VALID: 'Valid from',
  EXPIRY: 'Expires end',

  NOT_FOUND: 'Page not found',
  NOT_FOUND_DESCRIPTION: 'Sorry about that! Please visit our hompage to get where you need to go.',
  GO_HOME: 'Go Home',

  CANT_DELETE_READ_ACCESS: 'You cannot delete scanned accesses.',

  INVITE_NEW_USER: 'Invite new user',

  EVENT_TICKETS: 'Event tickets',
  ADD_TICKETS: 'Add Tickets',
  TICKET_AVAILABLE_UNTIL: 'Available until',
  TICKET_PRICE: 'Ticket Price',
  TICKET_TYPE: 'Ticket type',
  TICKET_QUANTITY: 'Ticket quantity',
  PENDING_TICKET_QUANTITY: 'Pending tickets',
  INVALID_DATE: 'Must be after the current date.',
  TICKETS_UPDATED: 'You have successfully updated the event tickets',
  TICKET_ID: 'Ticket id',
  NEGATIVE_FIELDS: 'You have negative fields.',
  CONTACT_TO_ADD_AND_BUY_TICKETS: 'To enable ticket creation and purchase',

  PAYMENT_METHODS: 'Payment methods',

  PASSWORD_REQUIRED: 'Password is required',
  CONFIRMATION_PASSWORD_REQUIRED: 'You must confirm the password',
  PASSWORD_LENGHT_REQUIRED: 'Password must be at least 6 characters',
  CHANGE_PASSWORD_NEXT_STEP: 'After completing the form you must log in with the corresponding credentials',
  ENTER_PASSWORD: 'Enter password',
  RIGHTS_RESERVED: '2023 Qr Event Manager. All rights reserved.',

  OPTIONAL_VALIDATIONS: 'Optional validations',
  VALIDATE_DNI: 'Validar CI para acceder al evento',
  PLACE: 'Place',
  PINPOINT_ON_MAP: 'Pinpoint on the map',
  HIDE_MAP_PINPOINT: 'Hide map pinpoints',
  DISABLE_MAP_MARKING: 'Disable map marking',
  BUY_TICKET: 'Buy ticket',
  TICKETS_UNAVAILABLE: 'The number of tickets exceeds the maximum available.',
  TICKETS_GREATER_THAT_ZERO: 'The number of tickets must be greater than 0.',
  LOCATE: 'Locate',

  TOTAL: 'Total',
  CUSTOMER_INFO: 'Customer information',
  COMPANION_INFO: 'Information of companion',
  BUY_TICKETS: 'Buy tickets for',
  SELECT_TICKETS_AMOUNT: 'Select amount of tickets',
  ENTER_TICKETS_INFO: 'Enter the requested information',
  GO: 'Go',
  NO_TICKETS_AVAILABLE: 'No tickets available',
  TICKETS_LEFT: 'Few tickets left',
  RETURN_HOME: 'Return to home page',
  FEW_TICKETS_LEFT: 'Few tickets left!',
  TICKETS_AVAILABLE: 'Tickets available!',
  VERY_FEW_TICKETS_LEFT: 'Very few tickets left!',
  X_TICKETS_LEFT: 'Only {{value}} tickets left!',
  NO_AVAILABLE_TICKETS: 'No tickets left',
  DESCRIPTION: 'Description',
  QUANTITY_MUST_BE_GREATER_THAN_BUYED: 'Ticket quantity must be greater than tickets buyed',
  GREATER_THAN_X: 'Greater than {{x}}',
  BETWEEN_X_AND_Y: 'Between {{x}} and {{y}}',

  TICKET_OWNER_INFORMATION: '"{{value}}" tickets owners information',
  SUMMARY: 'Summary',
  THANKS_FOR_PURCHASE: 'Thanks for your purchase!',
  BUY_ANOTHER_TICKET: 'Buy another ticket',
  INVALID_STEP: 'Invalid Step',
  SELECT_PAYMENT_GATEWAY: 'Select a payment gateway',

  EVENT_CUSTOM_FIELDS: 'Event custom fields',
  ADD_FIELD: 'Add field',
  REQUIRED: 'Required',
  UNIQUE: 'Unique',
  TYPE: 'Type',
  TEXT: 'Text',
  NUMBER: 'Number',
  DATE: 'Date',
  DONT_SHOW_ON_QR_READ: "Don't show on QR read",
  VALIDATIONS: 'Validations',

  //ERROR CODES
  UNEXPECTED_ERROR: 'Unexpected error. Please, try again',
  UNKNOWN: 'An error occurred while processing your payment. Please, try again.',
  BODY_VALIDATION_ERROR: 'An error occurred while processing your payment. Please, try again.',
  GATEWAY_PAYMENT_ERROR: 'An error occurred while processing your payment. Please, try again.',
  CARD_VALIDATION_ERROR: 'Your card data is incorrect, please, check it and try again.',
};

export default English;
