import { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useLanguage } from 'src/contexts/language.context';
import Text from 'src/components/Text';
import Suggestions from '../Landing/Suggestions';
import { TabPanel, a11yProps } from '../Landing/Tips';

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalSuggestions: FC<IProps> = ({ onClose, isOpen }) => {
  const { getTranslation } = useLanguage();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const width = window.innerWidth;

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      title={getTranslation('CREATE')}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
    >
      <DialogTitle>
        <Text>SUGGESTIONS</Text>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ bgcolor: 'background.paper', height: 'auto' }} className=" md:px-4 py-4 md:py-1 px-4 md:flex">
          <Tabs
            orientation={width < 900 ? 'horizontal' : 'vertical'}
            variant={width < 900 ? 'fullWidth' : 'scrollable'}
            value={activeTab}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="md:mr-10 !overflow-visible"
            sx={{ borderRight: { md: 2 }, borderColor: { md: 'divider' }, BorderBottom: { md: 20 } }}
          >
            <Tab label={getTranslation('USERS')} {...a11yProps(0)} />
            <Tab label={getTranslation('EVENTS')} {...a11yProps(1)} />
            <Tab label={getTranslation('ACCESSES')} {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Suggestions type="users" amount={3} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Suggestions type="events" amount={2} />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Suggestions type="access" amount={4} />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          <Text>CLOSE</Text>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSuggestions;
