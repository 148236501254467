import * as React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Text from 'src/components/Text';

import Suggestions from './Suggestions';
import { useLanguage } from 'src/contexts/language.context';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Tips() {
  const [value, setValue] = React.useState(0);
  const { getTranslation } = useLanguage();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const width = window.innerWidth;

  return (
    <section id="suggestion" className="bg-white">
      <Text className="text-gray-600 text-3xl md:text-3xl xl:text-4xl pt-8 text-center font-bold font-heading tracking-px-n leading-none">
        SUGGESTIONS
      </Text>
      <Box
        sx={{ bgcolor: 'background.paper', height: 'auto', width: { md: '100%', lg: '60%' }, margin: 'auto' }}
        className=" md:px-24 md:mx-10 py-4 md:py-24 px-4 md:flex"
      >
        <Tabs
          orientation={width < 900 ? 'horizontal' : 'vertical'}
          variant={width < 900 ? 'fullWidth' : 'scrollable'}
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="md:mr-10 !overflow-visible"
          sx={{
            borderRight: { md: 2 },
            borderColor: { md: 'divider' },
            BorderBottom: { md: 20 },
          }}
        >
          <Tab label={getTranslation('USERS')} {...a11yProps(0)} />
          <Tab label={getTranslation('EVENTS')} {...a11yProps(1)} />
          <Tab label={getTranslation('ACCESSES')} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Suggestions type="users" amount={3} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Suggestions type="events" amount={2} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Suggestions type="access" amount={4} />
        </TabPanel>
      </Box>
    </section>
  );
}
