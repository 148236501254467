import { FC, ReactNode, useEffect } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'src/contexts/auth.context';

import Router from 'src/routes/Router';
import Toast from 'src/components/Toast';
import Loading from 'src/components/Loading';
import NavBar from 'src/components/NavBar/NavBar';
import useStore from 'src/store';
import Footer from './Landing/Footer';

interface IProps {
  children: ReactNode | ReactNode[];
}

const PrivateLayout: FC<IProps> = ({ children }) => (
  <>
    <NavBar />
    <div className="w-full grow">
      <Box className="container px-4 pb-20" component="div">
        <div className="grid grid-cols-1 p-2">
          <div className="my-4">{children}</div>
        </div>
      </Box>
    </div>
    <Footer />
  </>
);

const PublicLayout: FC<IProps> = ({ children }) => <Box component="div">{children}</Box>;

export default function Main() {
  const auth = useAuth();
  const location = useLocation();
  const isLoading = useStore(state => state.isLoading);

  useEffect(() => {
    // @ts-ignore
    if (window.ga) window.ga('send', 'pageview', location.pathname);
  }, [location]);

  const Layout = auth?.isAuthenticated ? PrivateLayout : PublicLayout;

  return (
    <main>
      <div className="bg-gray-100 min-h-screen w-full flex flex-col">
        <Layout>
          <Router />
          <Toast />
        </Layout>
      </div>
      <Loading isOpen={isLoading} />
    </main>
  );
}
