import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { toast, Zoom } from 'react-toastify';
import { useLanguage } from 'src/contexts/language.context';

import useStore from 'src/store';

const CONFIG_MESSAGE = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const Toast = () => {
  const { message, type, options } = useStore(
    state => ({
      message: state.message,
      type: state.type,
      options: state.options,
    }),
    shallow,
  );
  const clearMessage = useStore.use.clearMessage();
  const dismissLoading = useStore.use.dismissLoading();
  const { getTranslation } = useLanguage();

  useEffect(() => {
    if (message) {
      dismissLoading();
      toast(getTranslation(message, options), {
        ...CONFIG_MESSAGE,
        type: type,
        position: 'top-right',
        transition: Zoom,
      });
      clearMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return null;
};

export default Toast;
