import { CompanyApiKeyStatusResponse, TCompanyApiKey } from 'src/types/companyApiKey.type';

import { IApiResponse } from './api/interfaces/companyApiKey';
import { apiCallCreateCompanyApiKey, apiCallDeleteCompanyApiKey } from './api/companyApiKeys.api';

export const createCompanyApiKey = async (companyId: string, name: string): Promise<IApiResponse> => {
  try {
    const response = await apiCallCreateCompanyApiKey(companyId, name);
    return {
      status: CompanyApiKeyStatusResponse.success,
      data: response as any as TCompanyApiKey,
    };
  } catch (error: any) {
    return {
      status: CompanyApiKeyStatusResponse.error,
      code: error?.code || '',
      message: error?.message || error,
    };
  }
};

export const deleteCompanyApiKey = async (companyId: string, tokenId: string): Promise<IApiResponse> => {
  try {
    await apiCallDeleteCompanyApiKey(companyId, tokenId);
    return {
      status: CompanyApiKeyStatusResponse.success,
    };
  } catch (error: any) {
    return {
      status: CompanyApiKeyStatusResponse.error,
      code: error?.code || '',
      message: error?.message || error,
    };
  }
};
