import { EventAccessesType } from 'src/constants/events';
import { PaymentStatus } from './payment.type';
import Languages from 'src/constants/languages';

export interface ISchedules {
  [key: number | string]: number | string;
}

export interface IPrices {
  [key: number | string]: number | string;
}

export interface IGenders {
  female: number;
  male: number;
  others: number;
}

export interface IStats {
  accesses: number;
  accesses_read: number;
  schedules: ISchedules;
  genders?: IGenders;
  prices?: IPrices;
  revenue?: number;
}

export enum EventStatusEnum {
  active = 'active',
  finished = 'finished',
  deleted = 'deleted',
}

export interface IEventPayment {
  id?: string;
  status: PaymentStatus;
  event_type: keyof typeof EventAccessesType;
}

export interface EventCustomField {
  name: string;
  type: 'date' | 'text' | 'number' | 'email';
  dontShowOnQRRead?: boolean;
  validations: string[];
}

export interface IEvent {
  id: string;
  name: string;
  date: Date;
  publicId?: number;
  image?: string;
  imageSmall?: string;
  mobileImage?: string;
  description?: string;
  location?: string | null;
  place?: string | null;
  allowToReadFrom?: Date | null;
  allowToReadTo?: Date | null;
  accessesId?: string | null;
  stats?: IStats;
  createdBy?: string;
  createdAt?: Date;
  status?: EventStatusEnum;
  firstScan?: Date | string;
  type?: keyof typeof EventAccessesType;
  tags?: { [key: string]: string };
  columns?: { [key: string]: boolean };
  payment?: IEventPayment;
  tickets?: { [key: string]: ITicket };
  specialConditions?: string[];
  socialLinks?: {
    instagram: string;
    twitter: string;
    facebook: string;
  };
  mercadopago?: {
    token?: string;
  };
  eventLanguage?: Languages;
  optionalValidations?: { [key: string]: string };
  eventCustomFields?: EventCustomField[];
  private?: boolean;
}
export interface IBuyers {
  ticketId: string;
  ticketType: string;
  ticketValue: number;
}

export interface IMetaData {
  user_id?: string;
  company_id?: string;
  event_id?: string;
  event_type?: string;
  ticket_amount?: number;
  user_mail?: string;
  user_name?: string;
  buyers_info?: IBuyers[];
}
export interface PendingTicket {
  quantity: number;

  token: string;

  validUntil: string;
}
export interface ITicket {
  id?: string;
  type?: string;
  price?: number;
  quantity?: number;
  quantityAvailable?: string;
  availableUntil?: string;
  benefits?: string;
  pendingQuantity?: PendingTicket[];
}
