import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { NewReleases } from '@mui/icons-material';

import Text from 'src/components/Text';
import system from 'src/constants/system';

import { PaymentStatusResponse, PaymentTypes } from 'src/types/payment.type';
import { sendPaymentData } from 'src/services/payment.service';

import { useLanguage } from 'src/contexts/language.context';
import { IDocumentType, IInstallment, IIssuer, IPayerCost, IPaymentMethod } from './interfaces';
import PaymentMethods from 'src/types/payment.type';
import { IMetaData } from 'src/types/event.type';
import Languages from 'src/constants/languages';

interface IProps {
  show: boolean;
  email: string;
  amount: number;
  type: PaymentTypes;
  description: string;
  extraData?: IMetaData;
  onSubmit: (value: PaymentStatusResponse) => void;
  eventLanguage?: Languages;
  mercadopagoToken?: string;
}

const MercadoPagoPayment = ({
  amount,
  email,
  description,
  show,
  type,
  onSubmit,
  extraData,
  eventLanguage,
  mercadopagoToken,
}: IProps) => {
  const { getTranslation } = useLanguage();

  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);

  const [documentTypes, setDocumentTypes] = useState<IDocumentType[]>([]);
  const [documentType, setDocumentType] = useState<IDocumentType | null>(null);

  const [issuer, setIssuer] = useState<IIssuer | null>(null);
  const [issuers, setIssuers] = useState<IIssuer[]>([]);

  const [installment, setInstallment] = useState<IPayerCost | null>(null);
  const [installments, setInstallments] = useState<IPayerCost[]>([]);

  const [cardNumber, setCardNumber] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null);

  const [formEmail, setFormEmail] = useState(email);

  const mercadoPago = useMemo(
    // @ts-ignore
    () => new MercadoPago(mercadopagoToken, { locale: 'en-US', advancedFraudPrevention: true }),
    [],
  );

  useEffect(() => {
    setCardNumber(null);
    setLoading(true);
    const style = {
      padding: '8px 6px',
      color: '#4B5563',
    };
    const cardNumberElement = mercadoPago.fields.create('cardNumber', {
      placeholder: 'XXXX XXXX XXXX XXXX XXXX',
      style,
    });
    const expirationDateElement = mercadoPago.fields.create('expirationDate', { placeholder: 'MM/YY', style });
    const securityCodeElement = mercadoPago.fields.create('securityCode', { placeholder: '123', style });
    cardNumberElement.mount('mercadopago-card-number');
    expirationDateElement.mount('mercadopago-expiration-date');
    securityCodeElement.mount('mercadopago-security-code');
    getDocumentTypes();
    cardNumberElement.on('binChange', async (data: any) => {
      const { bin } = data;
      try {
        if (!bin && paymentMethod?.id !== undefined) setPaymentMethod(null);
        if (bin && bin !== cardNumber) {
          const { results }: { results: IPaymentMethod[] } = await mercadoPago.getPaymentMethods({ bin });
          const newPaymentMethod = results[0] || null;
          setPaymentMethod(newPaymentMethod);
          if (newPaymentMethod) {
            const { settings } = newPaymentMethod;
            const cardNumberSettings = settings[0].card_number;
            cardNumberElement.update({ settings: cardNumberSettings });
            const securityCodeSettings = settings[0].security_code;
            securityCodeElement.update({ settings: securityCodeSettings });
            updateIssuer(newPaymentMethod);
            getInstallments(newPaymentMethod, bin);
          }
        }
        setCardNumber(bin);
      } catch (e) {
        console.error('error getting payment methods: ', e);
      }
    });
    return () => {
      cardNumberElement && cardNumberElement?.unmount();
      securityCodeElement && securityCodeElement?.unmount();
      expirationDateElement && expirationDateElement?.unmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function updateIssuer(newPaymentMethod: IPaymentMethod) {
    const { additional_info_needed, issuer } = newPaymentMethod;
    let issuerOptions = [issuer];
    if (additional_info_needed.includes('issuer_id')) issuerOptions = await getIssuers(newPaymentMethod);
    setIssuers(issuerOptions);
  }

  async function getIssuers(newPaymentMethod: IPaymentMethod) {
    try {
      const { id: paymentMethodId } = newPaymentMethod;
      return await mercadoPago.getIssuers({ paymentMethodId, bin: cardNumber });
    } catch (e) {
      // TODO: Resolve error
      console.error('error getting issuers: ', e);
    }
  }

  const getDocumentTypes = async () => {
    try {
      const identificationTypes = await mercadoPago.getIdentificationTypes();
      setDocumentTypes(identificationTypes);
      setDocumentType(identificationTypes.find((item: IDocumentType) => item.id === 'CI') || null);
      setLoading(false);
    } catch (e) {
      // TODO: Resolve error
      return console.error('Error getting identificationTypes: ', e);
    }
  };

  async function getInstallments(newPaymentMethod: IPaymentMethod, bin: string) {
    try {
      const installments: IInstallment[] = await mercadoPago.getInstallments({
        bin,
        amount: `${amount}`,
        paymentTypeId: newPaymentMethod.payment_type_id,
      });
      const installmentOptions = installments[0]?.payer_costs || [];
      setInstallments(installmentOptions);
    } catch (error) {
      // TODO: Resolve error
    }
  }

  const onChangeDocumentType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setDocumentType(documentTypes.find(item => item.id === value) || null);
  };

  const onChangeIssuer = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setIssuer(issuers.find(item => item.id === +value) || null);
  };

  const onChangeInstallment = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;
    setInstallment(installments.find(item => item.installments === +value) || null);
  };

  const renderStatusScreenBrick = async (paymentId: number) => {
    setLoading(true);
    setSuccess(true);
    const settings = {
      initialization: { paymentId },
      callbacks: {
        onReady: () => setLoading(false),
        onError: (error: any) => {
          console.log('DEBUG: ', error);
        },
      },
    };
    // @ts-ignore
    const bricksBuilder = mercadoPago.bricks();
    await bricksBuilder.create('statusScreen', 'mercadopago-payment-details', settings);
  };

  const onSubmitPayment = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const { target: targets } = event;
    const formValues: { [key: string]: string } = {};
    let emptyValue = false;
    for (const target of targets) {
      if (target.name) {
        formValues[target.name] = target.value;
        if (!target.value) {
          emptyValue = true;
        }
      }
    }

    if (emptyValue || !cardNumber) {
      setLoading(false);
      return setError('FIELDS_REQUIRED');
    }
    try {
      const cardToken = await mercadoPago.fields.createCardToken({ ...formValues });
      formValues.token = cardToken.id;
    } catch (e) {
      setLoading(false);
      return setError('CARD_FIELDS_REQUIRED');
    }
    const body = {
      token: formValues.token,
      issuer_id: formValues.issuer,
      description: formValues.description,
      installments: Number(formValues.installments || 1),
      payment_method_id: formValues.paymentMethodId,
      statement_descriptor: description.replace(/ /g, '_'),
      transaction_amount: Number(formValues.transactionAmount),
      payer: {
        email: formValues.email,
        identification: {
          type: formValues.identificationType,
          number: formValues.identificationNumber,
        },
      },
    };
    const data = { metadata: body, ...extraData, payer_email: formValues.email };
    let response = null;
    // TODO: Hacer que esta parte sea dinamica, puede ser un pago o una subscripción
    if (type === PaymentTypes.payment) response = await sendPaymentData(PaymentMethods.mercadopago, data);
    else response = await sendPaymentData(PaymentMethods.mercadopago, data);
    if (response.status === PaymentStatusResponse.error) {
      let error_code = response.code;
      if (!error_code) {
        error_code = (response.message as any).error.code as string;
      }
      setError(error_code?.toUpperCase() || '');
      setLoading(false);
      return null;
    }
    onSubmit(response.status);
    if (response.status === PaymentStatusResponse.pending) {
      setLoading(false);
      return setPending(true);
    }
    renderStatusScreenBrick(response?.data?.id);
  };

  const inputClassName =
    'h-[40px] w-full inline-block border-[1px] border-solid border-gray-300 rounded-md text-gray-600';
  const labelClassName = 'text-xs md:leading-[0.1rem] md:pl-3 md:pr-3 md:absolute md:bg-white';

  const renderContent = () => {
    if (success) return <div id="mercadopago-payment-details" className="relative min-h-[500px]"></div>;
    if (pending)
      return (
        <div className="text-center">
          <div className="text-8xl mb-3">
            <NewReleases className="text-yellow-500" fontSize="inherit" />
          </div>
          <Text bold className="mb-1 text-lg" language={eventLanguage}>
            PAYMENT_PENDING
          </Text>
          <Text className="mb-5 text-sm" language={eventLanguage}>
            PAYMENT_PENDING_MESSAGE
          </Text>
        </div>
      );
    return (
      <form id="checkout-container" className="flex flex-col gap-5 p-2" onSubmit={onSubmitPayment}>
        <div className="grid grid-cols-2 gap-5">
          <div className="relative col-span-2">
            <Text className={labelClassName} language={eventLanguage}>
              CARD_NUMBER
            </Text>
            <div id="mercadopago-card-number" className={`${inputClassName} w-full`}></div>
            {paymentMethod && (
              <div className="h-[34px] absolute bottom-[6px] right-2 flex items-center">
                <img className="w-full object-scale-down" src={paymentMethod?.thumbnail} alt="Method id logo" />
              </div>
            )}
          </div>
          <div>
            <Text className={labelClassName} language={eventLanguage}>
              EXPIRATION_DATE
            </Text>
            <div id="mercadopago-expiration-date" className={inputClassName}></div>
          </div>
          <div>
            <Text className={labelClassName} language={eventLanguage}>
              SECURITY_CODE
            </Text>
            <div id="mercadopago-security-code" className={inputClassName}></div>
          </div>
        </div>
        <div>
          <Text className={labelClassName} language={eventLanguage}>
            CARD_HOLDER
          </Text>
          <input
            type="text"
            name="cardholderName"
            id="checkout-container-card-holder-name"
            placeholder={getTranslation('CARD_HOLDER')}
            className={`${inputClassName} px-2`}
          />
        </div>
        {issuers.length > 1 ? (
          <div>
            <Text className={labelClassName} language={eventLanguage}>
              IDENTIFICATION_TYPE
            </Text>
            <select
              name="issuer"
              value={issuer?.name}
              id="checkout-container-issuer"
              onChange={event => onChangeIssuer(event)}
              className={`${inputClassName} px-1 col-span-2 md:col-span-1`}
            >
              {issuers.map((item, index) => (
                <option key={`${index}-${item.id}`} value={item.id} selected={item.id === issuer?.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        {installments.length > 1 ? (
          <div>
            <Text className={labelClassName} language={eventLanguage}>
              INSTALLMENTS
            </Text>
            <select
              name="installments"
              value={issuer?.name}
              id="checkout-container-installments"
              onChange={event => onChangeInstallment(event)}
              className={`${inputClassName} px-1 col-span-2 md:col-span-1`}
            >
              {installments.map((item, index) => (
                <option
                  key={`${index}-${item.installments}`}
                  value={item.installments}
                  selected={item.installments === installment?.installments}
                >
                  {item.recommended_message}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <div className="grid grid-cols-5 gap-5">
          {documentTypes.length > 0 ? (
            <div className="col-span-2 md:col-span-1">
              <Text className={labelClassName} language={eventLanguage}>
                CARD_ISSUER
              </Text>
              <select
                name="identificationType"
                value={documentType?.name}
                className={`${inputClassName} px-1`}
                id="checkout-container-identification-type"
                onChange={event => onChangeDocumentType(event)}
              >
                {documentTypes.map((item, index) => (
                  <option key={`${index}-${item.id}`} value={item.id} selected={item.id === documentType?.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <div className="col-span-3 md:col-span-4">
            <Text className={labelClassName} language={eventLanguage}>
              IDENTIFICATION_NUMBER
            </Text>
            <input
              type="text"
              name="identificationNumber"
              id="checkout-container-identification"
              maxLength={documentType?.max_length || 0}
              minLength={documentType?.min_length || 0}
              className={`${inputClassName} px-2 w-full`}
              placeholder={documentType?.name || getTranslation('IDENTIFICATION_NUMBER')}
            />
          </div>
        </div>
        <div>
          <Text className={labelClassName} language={eventLanguage}>
            Email
          </Text>
          <input
            type="email"
            name="email"
            value={formEmail}
            id="checkout-container-email"
            className={`${inputClassName} px-2`}
            placeholder={getTranslation('EMAIL', null, eventLanguage)}
            onChange={({ target: { value } }) => setFormEmail(value)}
          />
        </div>
        <input id="description" name="description" type="hidden" value={description} />
        <input id="transactionAmount" name="transactionAmount" type="hidden" value={amount} />
        <input id="paymentMethodId" name="paymentMethodId" type="hidden" value={paymentMethod?.id || ''} />
        <div className="col-span-2 text-right">
          <button
            type="submit"
            id="checkout-container-submit"
            className="bg-blue-500 px-5 py-3 rounded-xl font-bold text-white"
          >
            <Text simple language={eventLanguage}>
              PAY
            </Text>
          </button>
        </div>
      </form>
    );
  };

  return (
    <>
      {loading ? (
        <div className="z-[100] flex absolute bg-gray-200 bg-opacity-50 rounded-lg top-0 w-full h-full">
          <CircularProgress size={35} className="m-auto animate-spin" color="primary" />
        </div>
      ) : null}
      {error ? (
        <Text simple className="text-center text-red-600 font-bold" options={{ amount }} language={eventLanguage}>
          {error}
        </Text>
      ) : null}
      {renderContent()}
    </>
  );
};

export default MercadoPagoPayment;
