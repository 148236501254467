export enum CompanyApiKeyStatusResponse {
  error = 'error',
  success = 'success',
}

export type TCompanyApiKey = {
  id?: string;
  name: string;
  token: string;
};
