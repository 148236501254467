import { createContext, useContext } from 'react';

import CompanyRoles from 'src/constants/roles';
import { ICompany, ISubscription } from 'src/types/company.type';
import { useAuth } from 'src/contexts/auth.context';
import { IInvitation } from 'src/types/invitations.type';
import { useLanguage } from 'src/contexts/language.context';
import {
  createInvitation,
  updateCompanyInformation,
  updateCompanySubscription,
  updatePaymentToken,
} from 'src/services/database/company.service';
import Languages from 'src/constants/languages';
import useStore from 'src/store';
import { IGatewayToken } from 'src/types/token.type';
import PaymentMethods from 'src/types/payment.type';

const CompaniesContext = createContext<ICompanyContext | null>(null);

interface ICompanyContext {
  addInvitation: (
    firstName: string,
    lastName: string,
    email: string,
    role: CompanyRoles,
    language?: Languages,
  ) => Promise<{ status: boolean; message: string }>;
  updateCompany: (company: ICompany) => Promise<boolean>;
  updateSubscription: (id: string, subscription?: ISubscription) => Promise<boolean>;
  addPaymentMethodToken: (
    token: string,
    paymentMethod: PaymentMethods,
  ) => Promise<{ status: boolean; message: string }>;
}

export function useCompanyContext(): ICompanyContext | null {
  return useContext(CompaniesContext);
}

export function CompaniesProvider({ children }: any) {
  const auth = useAuth();
  const showMessage = useStore.use.showMessage();
  const { getTranslation } = useLanguage();

  async function addInvitation(
    firstName: string,
    lastName: string,
    email: string,
    role: CompanyRoles,
    language?: Languages,
  ): Promise<{ status: boolean; message: string }> {
    if (auth) {
      const { currentUser } = auth;
      if (currentUser?.companies && auth?.currentCompany?.id) {
        const invitation: IInvitation = {
          firstName,
          lastName,
          email,
          role,
          company: auth.currentCompany.id,
          language,
        };
        return await createInvitation(invitation);
      }
    }
    return {
      status: false,
      message: 'UNAUTHORIZED',
    };
  }

  async function updateCompany(company: ICompany) {
    if (!company.name.trim()) {
      showMessage('ATTRIBUTE_REQUIRED', 'error', { value: getTranslation('COMPANY_NAME') });
      return false;
    }
    if (!company.country.trim()) {
      showMessage('ATTRIBUTE_REQUIRED', 'error', { value: getTranslation('COUNTRY') });
      return false;
    }
    const result = await updateCompanyInformation(company, auth?.currentUser?.uid);
    if (!result) showMessage('AN_ERROR_OCURRED', 'error');
    return result;
  }

  async function updateSubscription(id: string, subscription?: ISubscription) {
    const result = await updateCompanySubscription(id, subscription);
    if (!result) showMessage('AN_ERROR_OCURRED', 'error');
    auth?.currentCompany && auth.setCurrentCompany({ ...auth.currentCompany, subscription });
    return result;
  }

  async function addPaymentMethodToken(token: string, paymentMethod: PaymentMethods) {
    if (auth) {
      const { currentUser } = auth;
      if (currentUser?.companies && auth?.currentCompany?.id) {
        const tokenData: IGatewayToken = {
          token,
          companyId: auth.currentCompany.id,
          paymentMethod,
        };
        return await updatePaymentToken(tokenData);
      }
    }
    return {
      status: false,
      message: 'UNAUTHORIZED',
    };
  }

  const value = {
    addInvitation,
    updateCompany,
    updateSubscription,
    addPaymentMethodToken,
  };

  return <CompaniesContext.Provider value={value}>{children}</CompaniesContext.Provider>;
}
