import React, { createContext, useReducer } from 'react';
import { initialValues } from 'src/contexts/signUpInitialValues';
import { useLanguage } from './language.context';

const isText = RegExp(/^[A-Z ]+$/i);
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/); // us
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/); // us
const isNumber = RegExp(/^\d+$/);

// Applied to all fields
const variant = 'standard';
const margin = 'normal';

export declare type ValidationSchema = Record<
  string,
  {
    value?: any;
    error?: string;
    required?: boolean;
    validate?: 'text' | 'number' | 'email' | 'phone' | 'zip' | 'checkbox' | 'select';
    minLength?: number;
    maxLength?: number;
    helperText?: string;
  }
>;

type ContextProps = {
  activeStep: number;
  formValues: ValidationSchema;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => void;
  handleNext: () => void;
  handleBack: () => void;
  variant: 'outlined' | 'standard' | 'filled';
  margin: 'dense' | 'normal' | 'none';
  handleSubmit: () => void;
  handleSubmitted: (signupError?: string) => void;
  loading: boolean;
  signupError?: string;
};

export const FormContext = createContext<ContextProps>({
  activeStep: 0,
  formValues: initialValues,
  handleChange() {},
  handleNext() {},
  handleBack() {},
  variant,
  margin,
  handleSubmit() {},
  handleSubmitted() {},
  loading: false,
  signupError: undefined,
});

interface ProviderProps {
  children: React.ReactNode;
}

type State = {
  activeStep: number;
  formValues: ValidationSchema;
  loading: boolean;
  signupError?: string;
};

type Action =
  | { type: 'increase' }
  | { type: 'decrease' }
  | { type: 'form-value'; name: string; fieldValue: any }
  | { type: 'form-error'; name: string; error: string }
  | { type: 'sign_up_submitted' }
  | { type: 'sign_up_complete'; signupError?: string };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'increase':
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case 'decrease':
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case 'form-value':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            value: action.fieldValue,
          },
        },
      };
    case 'form-error':
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.name]: {
            ...state.formValues[action.name],
            error: action.error,
          },
        },
      };
    case 'sign_up_submitted':
      return {
        ...state,
        loading: true,
        signupError: undefined,
      };
    case 'sign_up_complete':
      return {
        ...state,
        activeStep: !!action.signupError ? state.activeStep : 0,
        formValues: !!action.signupError ? state.formValues : initialValues,
        loading: false,
        signupError: action.signupError,
      };
    default:
      return state;
  }
}

export function StepsProvider({ children }: ProviderProps) {
  const [{ activeStep, formValues, loading, signupError }, dispatch] = useReducer(reducer, {
    activeStep: 0,
    formValues: initialValues,
    loading: false,
    signupError: undefined,
  });

  const { getTranslation } = useLanguage();

  // Proceed to next step
  const handleNext = () => dispatch({ type: 'increase' });
  // Go back to prev step
  const handleBack = () => dispatch({ type: 'decrease' });

  // Handle form change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, checked?: boolean) => {
    const { type, name, value } = event.target;

    const fieldValue = type === 'checkbox' ? checked : value;

    dispatch({ type: 'form-value', name, fieldValue });

    const fieldName = initialValues[name];
    if (!fieldName) return;

    const { required, validate, minLength, maxLength, helperText } = fieldName;

    let error = '';

    if (name === 'confirmPassword') {
      if (value !== formValues.password.value) error = getTranslation('PASSWORD_CONFIRMATION_NOT_MATCH');
    }
    if (required && !fieldValue) error = getTranslation('FIELD_IS_REQUIRED');
    if (minLength && value && value.length < minLength)
      error = getTranslation('MINIMUM_CHARACTERS_IS_REQUIRED', { value: minLength });

    if (maxLength && value && value.length > maxLength) error = getTranslation('MAXIMUN_LENGTH_EXCEEDED');
    if (validate) {
      switch (validate) {
        case 'text':
          if (value && !isText.test(value)) error = helperText || getTranslation('FIELD_ACCEPTS_TEXT_ONLY');
          break;

        case 'number':
          if (value && !isNumber.test(value)) error = helperText || getTranslation('FIELD_ACCEPTS_NUMBERS_ONLY');
          break;

        case 'email':
          if (value && !isEmail.test(value)) error = helperText || getTranslation('INVALID_EMAIL');
          break;

        case 'phone':
          if (value && !isPhone.test(value)) error = helperText || getTranslation('ENTER_A_VALID_PHONE_NUMBER');
          break;

        case 'zip':
          if (value && !isZip.test(value)) error = helperText || getTranslation('ENTER_A_VALID_ZIP_CODE');
          break;

        case 'checkbox':
          if (!checked) error = helperText || getTranslation('PROVIDE_A_VALID_VALUE');
          break;

        case 'select':
          if (!value) error = helperText || getTranslation('SELECT_A_VALUE');
          break;

        default:
          break;
      }
    }

    dispatch({ type: 'form-error', name, error });
  };

  const handleSubmit = () => dispatch({ type: 'sign_up_submitted' });

  const handleSubmitted = (signupError?: string) => dispatch({ type: 'sign_up_complete', signupError });

  return (
    <FormContext.Provider
      value={{
        activeStep,
        formValues,
        handleChange,
        handleNext,
        handleBack,
        variant,
        margin,
        handleSubmit,
        handleSubmitted,
        loading,
        signupError,
      }}
    >
      <div className="mui-step-form">{children}</div>
    </FormContext.Provider>
  );
}
