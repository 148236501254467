import { FC, useMemo, useState } from 'react';
import { AllInclusive, Block } from '@mui/icons-material';
import { Slider, Stack } from '@mui/material';

import Text from 'src/components/Text';
import { ILIMITED_ACCESSES, ILIMITED_EVENTS } from '../Settings';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { useCompanyContext } from 'src/contexts/company.context';
import useStore from 'src/store';
import Payment from 'src/components/Payment/Payment';
import { PaymentStatusResponse, PaymentTypes } from 'src/types/payment.type';
import { UserItem } from 'src/types/user.type';
import { ICompany, ISubscription } from 'src/types/company.type';

const enum ConfirmationMode {
  closed = 0,
  cancel = 1,
  change = 2,
}

interface AvailableTicketProps {
  setSubscriptionEvents: React.Dispatch<React.SetStateAction<number>>;
  setSubscriptionAccesses: React.Dispatch<React.SetStateAction<number>>;
  getPricing: (events: number | null, accesses: number | null) => number;
  company: ICompany | null;
  subscription: ISubscription | null;
  subscriptionEvents: number;
  subscriptionAccesses: number;
  getData: () => Promise<null | undefined>;
  user: UserItem | null;
}

export const AvailableTickets: FC<AvailableTicketProps> = ({
  setSubscriptionEvents,
  setSubscriptionAccesses,
  getPricing,
  getData,
  company,
  subscription,
  subscriptionEvents,
  subscriptionAccesses,
  user,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(ConfirmationMode.closed);
  const companyContext = useCompanyContext();
  const showMessage = useStore.use.showMessage();
  const [showPayment, setShowPayment] = useState<boolean>(false);

  const onChangeValue = (event: Event, value: number | number[]) => {
    const {
      // @ts-ignore
      target: { name },
    } = event;
    if (name === 'events') {
      if (typeof value === 'number') return setSubscriptionEvents(value);
      return setSubscriptionEvents(value[0]);
    }
    if (typeof value === 'number') return setSubscriptionAccesses(value);
    return setSubscriptionAccesses(value[0]);
  };

  const actualPricing = useMemo(
    () => getPricing(company?.subscription?.events || null, company?.subscription?.accesses || null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscription],
  );

  const getButtonClass = () => {
    const color = estimatedPricing !== actualPricing ? 'bg-blue-600' : 'bg-blue-300';
    return `${color} px-3 py-1 rounded-md text-white font-bold transition-all duration-500`;
  };

  const onCancelSubscription = async () => {
    if (!companyContext || !company) return null;
    // TODO: Cancel subscription into payment integration here
    const result = await companyContext.updateSubscription(company.id);
    if (result) {
      showMessage('SUBSCRIPTION_SUCCESS_CANCELED', 'success');
      getData();
    }
  };

  const onCloseConfirmation = (confirm?: boolean) => {
    if (confirm) {
      switch (showConfirmation) {
        case ConfirmationMode.change:
          setShowPayment(true);
          break;
        case ConfirmationMode.cancel:
          onCancelSubscription();
          break;
        default:
          break;
      }
    }
    setShowConfirmation(ConfirmationMode.closed);
  };

  const onClosePaymentModal = (status?: PaymentStatusResponse) => {
    if (status === PaymentStatusResponse.success) getData();
    setShowPayment(false);
  };

  const estimatedPricing = useMemo(
    () => getPricing(subscriptionEvents, subscriptionAccesses),
    [subscriptionEvents, subscriptionAccesses, getPricing],
  );

  return (
    <>
      <section className="border-t-2 border-gray-300 mt-5 pt-5 py-3">
        <div className="flex flex-row items-center justify-between">
          <div>
            <Text bold>SUBSCRIPTION</Text>
            <div className="flex flex-nowrap text-sm">
              <Text>ACTUAL_SUBSCRIPTION</Text>:
              <Text bold className="pl-1">
                {actualPricing ? `$ ${actualPricing}` : 'FREE'}
              </Text>
            </div>
          </div>
          {subscription ? (
            <button
              className="bg-red-600 px-3 py-1 rounded-md mr-3"
              onClick={() => setShowConfirmation(ConfirmationMode.cancel)}
            >
              <Block className="text-white" />
            </button>
          ) : null}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 py-5">
          <div>
            <Text className="pb-2 text-sm">EVENTS</Text>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
              <span className="font-bold">3</span>
              <Slider
                marks
                min={3}
                step={7}
                max={ILIMITED_EVENTS}
                name="events"
                defaultValue={3}
                aria-label="Volume"
                valueLabelDisplay="auto"
                valueLabelFormat={value => <span>{value === ILIMITED_EVENTS ? '∞' : value}</span>}
                value={subscriptionEvents}
                onChange={onChangeValue}
              />
              <AllInclusive />
            </Stack>
          </div>
          <div>
            <Text className="pb-2 text-sm">ACCESSES</Text>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
              <span className="font-bold">100</span>
              <Slider
                marks
                min={100}
                step={400}
                max={ILIMITED_ACCESSES}
                name="accesses"
                defaultValue={100}
                aria-label="Volume"
                valueLabelDisplay="auto"
                valueLabelFormat={value => <span>{value === ILIMITED_ACCESSES ? '∞' : value}</span>}
                value={subscriptionAccesses}
                onChange={onChangeValue}
              />
              <AllInclusive />
            </Stack>
          </div>
        </div>
        <div className="flex justify-between items-center gap-x-1">
          <div>
            <div className="flex items-center">
              <Text className="text-sm">PRICE</Text>:
              <Text bold className="pl-2 text-xl">{`$ ${estimatedPricing}`}</Text>
            </div>
            <Text className="text-xs">ANNUAL_DISCOUNT</Text>
          </div>
          <div className="text-right">
            <button onClick={() => setShowConfirmation(ConfirmationMode.change)} className={getButtonClass()}>
              <Text simple>CHANGE</Text>
            </button>
          </div>
        </div>
      </section>
      <ConfirmationModal
        onClose={onCloseConfirmation}
        open={showConfirmation !== ConfirmationMode.closed}
        title={showConfirmation === ConfirmationMode.cancel ? 'CANCEL_SUBSCRIPTION' : 'CHANGE_SUBSCRIPTION'}
        text={showConfirmation === ConfirmationMode.cancel ? 'CANCEL_SUBSCRIPTION_MSG' : 'CHANGE_SUBSCRIPTION_MSG'}
      />

      {showPayment && user && company ? (
        <Payment
          email={user.email}
          amount={estimatedPricing}
          onClose={onClosePaymentModal}
          type={PaymentTypes.subscription}
          extraData={{
            company_id: company.id,
            user_id: user.id,
          }}
        />
      ) : null}
    </>
  );
};
