import { ICompany } from 'src/types/company.type';
import ApiKeys from './ApiKeys/ApiKeys';
import { MercadoPagoKeys } from './MercadoPagoKeys/MercadoPagoKeys';

export const CompanyKeys = ({ company, getData }: { company: ICompany | null; getData: () => void }) => {
  return (
    <section className="bg-white p-5 rounded-lg shadow-md mt-5">
      <MercadoPagoKeys company={company} getData={getData} />
      <ApiKeys />
    </section>
  );
};
