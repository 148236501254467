import dayjs from 'dayjs';
import {
  child,
  get,
  getDatabase,
  ref,
  query,
  orderByChild,
  limitToFirst,
  update,
  remove,
  startAt,
  startAfter,
  endAt,
  endBefore,
  limitToLast,
  push,
  equalTo,
} from 'firebase/database';

import { Access, AccessOrderBy } from 'src/types/access.type';
import { IEvent } from 'src/types/event.type';

export const getAccess = async (company: string, event: string, document: string): Promise<Access | null> => {
  const snapshot = await get(child(ref(getDatabase()), `accesses/${company}/${event}/${document}`));
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    return null;
  }
};

//add 0's at the end until complete 8 digits
function truncateNumber(value: string, length: number = 8, fill: string = '0') {
  return value.padEnd(length, fill);
}

//add one to the last digit
function parseEnd(toTruncate: number) {
  toTruncate = toTruncate + 1;
  var result = truncateNumber(toTruncate.toString());
  return result;
}

export const getAccesses = async (
  company: string,
  event: string,
  last: Access | null,
  rows: number,
  orderBy: AccessOrderBy,
  // pageChange: number,
  currentPage: number,
  filter: string = '',
): Promise<Access[]> => {
  try {
    const accessRef = child(ref(getDatabase()), `accesses/${company}/${event}`);

    const search = filter.trim() || null;
    const subQuery = [startAt(search), endAt(search + '\uf8ff')];

    // Pagination
    const startIndex = (currentPage - 1) * rows;
    const endIndex = startIndex + rows - 1;

    const queryPagination = limitToFirst(endIndex + 1);
    let preQuery = null;

    if (search && !Number.isNaN(+search) && +search > 0) {
      const documentStart = truncateNumber(search);
      const documentEnd = truncateNumber(search, 8, '9');
      preQuery = query(
        accessRef,
        orderByChild(AccessOrderBy.document),
        startAt(+documentStart),
        endAt(+parseEnd(+documentEnd)),
      );
    } else {
      if (search) preQuery = query(accessRef, orderByChild(orderBy), ...subQuery, queryPagination);
    }
    if (!preQuery) preQuery = query(accessRef, queryPagination);

    const snapshot = await get(preQuery);

    // preQuery = query(baseQuery, orderByChild(orderBy), startAfter(lastAccess ? lastAccess.document : 0));
    if (snapshot.exists()) {
      return Object.values(snapshot.val()).slice(startIndex, endIndex) as any[];
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const markAccessRead = async (
  company: string,
  event: string,
  document: string,
  userKey: string,
): Promise<boolean> => {
  try {
    await update(child(ref(getDatabase()), `accesses/${company}/${event}/${document}`), {
      read: true,
      read_by: userKey,
      read_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
    });
    return true;
  } catch (err: any) {
    return false;
  }
};

export const deleteAccesses = async (company: string, event: string, accessIds: string[]): Promise<boolean> => {
  try {
    const deletes = Object.fromEntries(accessIds.map(doc => [doc, null]));
    await update(child(ref(getDatabase()), `accesses/${company}/${event}/`), deletes);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const createMultipleAccesses = async (
  companyId: string,
  eventId: string,
  additions: any,
  sendEmail: boolean,
): Promise<{ success: boolean; message: string }> => {
  try {
    const eventSnapshot = await get(child(ref(getDatabase()), `events/${companyId}/${eventId}`));

    if (!eventSnapshot.exists())
      return {
        success: false,
        message: 'EVENT_NOT_FOUND',
      };

    const event: IEvent = JSON.parse(JSON.stringify(eventSnapshot.val()));

    let validAccesses = { success: true, message: 'SUCCESS' };

    const uniqueFields = event.eventCustomFields?.filter(field => field.validations?.includes('UNIQUE'));
    uniqueFields?.forEach(field => {
      Object.values(additions).forEach((access: any, index) => {
        additions[access.id][`unique_${index + 1}`] = `${field.name}-${access[field.name]}`;
      });
    });

    for (const access of Object.values<any>(additions)) {
      if (validAccesses.success) {
        const isValidAccess = await validateAccessWithEventValidations(access, event, companyId, eventId, sendEmail);

        validAccesses = isValidAccess;

        additions[access?.id] = lowerize(access);
      }
    }

    if (!validAccesses.success) return validAccesses;

    if (additions.send_mail) {
      const newObjectRef = await push(
        child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${additions.id}`),
        additions,
      );
      const newObjectId = newObjectRef.key;

      await remove(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${additions.id}`));

      await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${newObjectId}`), {
        ...additions,
        id: newObjectId,
      });
    } else {
      for (const [key, value] of Object.entries(additions)) {
        await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${key}`), value as any);
      }
    }

    return {
      success: true,
      message: 'ACCESSES_SUCCESFULLY_ADDED',
    };
  } catch (err: any) {
    return {
      success: false,
      message: 'UNEXPECTED_ERROR',
    };
  }
};

const lowerize = (obj: any) =>
  Object.keys(obj).reduce((acc: any, k: string) => {
    acc[k.toLowerCase()] = obj[k];
    return acc;
  }, {});

const validateAccessWithEventValidations = async (
  newAccess: any,
  event: IEvent,
  companyId: string,
  eventId: string,
  sendEmail: boolean,
) => {
  let validEvent = { success: true, message: 'SUCCESS' };

  if (!sendEmail && !!newAccess['document']) {
    const accessRef = (
      await get(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${newAccess['document']}`))
    ).val();

    validEvent = {
      success: accessRef === undefined || accessRef === null,
      message: accessRef === undefined || accessRef === null ? '' : `DUPLICATE_DOCUMENT_ON_ACCESS`,
    };
  }

  if (validEvent)
    event.eventCustomFields?.forEach(customField => {
      if (customField.validations?.includes('REQUIRED') && !newAccess[customField.name])
        validEvent = { success: false, message: 'MISSING_REQUIRED_FIELDS_ON_ACCESSES' };
    });

  if (validEvent && event.eventCustomFields?.filter(field => field.validations?.includes('UNIQUE')).length) {
    const validUniqueAccesses = await Promise.all(
      event.eventCustomFields
        ?.map(customField => {
          if (customField.validations?.includes('UNIQUE') && newAccess[customField.name]) {
            const accessRef = child(ref(getDatabase()), `accesses/${companyId}/${eventId}`);

            const preQuery1 = query(
              accessRef,
              orderByChild('unique_1'),
              equalTo(`${customField.name}-${newAccess[customField.name]}`),
            );
            const preQuery2 = query(
              accessRef,
              orderByChild('unique_2'),
              equalTo(`${customField.name}-${newAccess[customField.name]}`),
            );

            return [get(preQuery1), get(preQuery2)];
          }
          return [];
        })
        .flat(),
    );

    if (validUniqueAccesses.some(validAccess => validAccess && validAccess.exists())) {
      validEvent = { success: false, message: 'ALREADY_EXIST_UNIQUE_VALUE' };
    }
  }
  return validEvent;
};

export const resendEmails = async (companyId: string, eventId: string, accessesId: string[]) => {
  try {
    for (const accessId of accessesId) {
      await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${accessId}`), {
        email_sended: true,
        send_mail: true,
      });
      setTimeout(async () => {
        await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${accessId}`), {
          send_mail: true,
          email_sended: false,
        });
      }, 1000);
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const createAccesses = async (
  companyId: string,
  eventId: string,
  additions: any,
  sendEmail: boolean,
): Promise<{ success: boolean; message: string }> => {
  try {
    const eventSnapshot = await get(child(ref(getDatabase()), `events/${companyId}/${eventId}`));

    if (!eventSnapshot.exists())
      return {
        success: false,
        message: 'EVENT_NOT_FOUND',
      };

    const event: IEvent = JSON.parse(JSON.stringify(eventSnapshot.val()));

    const validAccess = await validateAccessWithEventValidations(additions, event, companyId, eventId, sendEmail);

    if (!validAccess.success) return validAccess;

    const uniqueFields = event.eventCustomFields?.filter(field => field.validations?.includes('UNIQUE'));

    uniqueFields?.forEach(
      (field, index) => (additions[`unique_${index + 1}`] = `${field.name}-${additions[field.name]}`),
    );

    if (sendEmail) {
      const newObjectRef = await push(
        child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${additions.id}`),
        additions,
      );
      const newObjectId = newObjectRef.key;

      await remove(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${additions.id}`));

      const accessToSave = {
        ...additions,
        id: newObjectId,
      };

      await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${newObjectId}`), accessToSave);
    } else {
      await update(child(ref(getDatabase()), `accesses/${companyId}/${eventId}/${additions.id}`), additions);
    }

    return {
      success: true,
      message: 'ACCESSES_SUCCESFULLY_ADDED',
    };
  } catch (err: any) {
    return {
      success: false,
      message: 'UNEXPECTED_ERROR',
    };
  }
};

export const deleteAccessesList = async (company: string, event: string): Promise<boolean> => {
  try {
    await remove(child(ref(getDatabase()), `accesses/${company}/${event}/`));
    return true;
  } catch (err: any) {
    return false;
  }
};
