import axios, { AxiosRequestConfig } from 'axios';
import { onAuthStateChanged, getAuth, getIdToken } from 'firebase/auth';
import promise from 'promise';
import system from 'src/constants/system';

var axiosApiKeyInstance = axios.create({
  baseURL: system.apiKeysURL,
});

const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async user => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        resolve(null);
      }
      unsub();
    });
  });
};

axiosApiKeyInstance.interceptors.request.use(async (request: AxiosRequestConfig) => {
  const token = await getUserToken();
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});

axiosApiKeyInstance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error?.response?.data) return promise.reject(error.response.data);
    return promise.reject(error);
  },
);

export default axiosApiKeyInstance;
