export function getPricing(events: number | null, accesses: number | null): number {
  if (!events || !accesses) return 0;
  switch (events) {
    case 3:
      switch (accesses) {
        case 100:
          return 50;
        case 500:
          return 200;
        default:
          return 500;
      }
    case 10:
      switch (accesses) {
        case 100:
          return 150;
        case 500:
          return 600;
        default:
          return 1500;
      }
    default:
      switch (accesses) {
        case 100:
          return 300;
        case 500:
          return 1200;
        default:
          return 3000;
      }
  }
}
