import { deleteObject, getStorage, listAll, ref, uploadBytesResumable } from 'firebase/storage';

export const uploadCompanyImage = async (companyId: string, file: File, userId: string): Promise<string> => {
  try {
    const storage = getStorage();
    const metadata = {
      contentType: file?.type,
      customMetadata: { uploadedBy: userId },
    };
    const extension = file.type.match(/[^/]*$/);
    if (!extension) throw new Error('AN_ERROR_OCURRED');
    const companyImageRef = ref(storage, `companies/${companyId}/image.${extension[0]}`);

    const uploadTask = uploadBytesResumable(companyImageRef, file, metadata);
    uploadTask.on(
      'state_changed',
      () => {},
      error => {
        throw new Error(error.message);
      },
    );
    const url = `https://firebasestorage.googleapis.com/v0/b/${
      companyImageRef.bucket
    }/o/${companyImageRef.fullPath.replace(/\//g, '%2F')}?alt=media&token=8a31a43a-8dcc-4a08-ab0f-976ce86e41e7`;
    return url;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteCompanyImage = async (companyId: string): Promise<undefined> => {
  try {
    const storage = getStorage();
    const companyRef = ref(storage, `companies/${companyId}`);

    const files = await listAll(companyRef);
    for (const item of files.items) {
      await deleteObject(item);
    }

    return;
  } catch (err: any) {
    throw new Error(err);
  }
};
