import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';

import Text from 'src/components/Text';

import { useLanguage } from 'src/contexts/language.context';
import { FC } from 'react';

export interface ConfirmationDialogRawProps {
  open: boolean;
  onClose: (value?: boolean) => void;
  title: string;
  text: string;
  loading?: boolean;
  confirmText?: string;
  declineText?: string;
}

const ConfirmationModal: FC<ConfirmationDialogRawProps> = ({
  onClose,
  open,
  loading,
  title,
  text,
  confirmText = 'ACCEPT',
  declineText = 'CANCEL',
}) => {
  const { getTranslation } = useLanguage();

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      title={getTranslation(title)}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    >
      <DialogTitle>
        <Text>{title}</Text>
      </DialogTitle>
      <DialogContent dividers>
        <Text>{text}</Text>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          <Text className="text-red-600">{declineText}</Text>
        </Button>
        <LoadingButton onClick={handleOk} loading={loading || false}>
          <Text simple>{confirmText}</Text>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
